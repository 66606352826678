import { FC, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import styles from 'styles/components/form.module.scss'
import InputTextWithLightLabel from 'components/Form/WithLightLabel/InputText'
import InputTextAndUnitWithLightLabel from 'components/Form/WithLightLabel/InputTextAndUnit'
import SubmitButton from 'components/Button/SubmitButton'
import InputTextSuggestWithLightLabel from 'components/Form/WithLightLabel/InputTextSuggest'
import { suggestInfo } from 'components/Form/WithLabel/InputTextSuggest'
import { getMaterialById, MaterialType } from 'reducks/material/slice'
import { getDoughById, DoughType } from 'reducks/dough/slice'
import { getFillingById, FillingType } from 'reducks/filling/slice'
import {
  getRecipeById,
  MaterialInformationType,
  RecipeType,
} from 'reducks/recipe/slice'
import {
  getMaterialSubCategoryById,
  MaterialSubCategoryType,
} from 'reducks/materialSubCategory/slice'
import {
  getMaterialCostByMaterialId,
  MaterialCostType,
} from 'reducks/materialCost/slice'
import addFormInfo from 'images/Form/add-form-info.png'

type Props = {
  formInfos: ProductInformation[]
  unit: string
  errorText?: string | null
  handleProductInformation: (item: ProductInformation[]) => void
  noAddButton?: boolean
}

export type ProductInformation = {
  productName: string
  manufacturerName: string
  categoryName: string
  middleKneadPercent: string
  authenticKneadPercent: string
  specifyByPiece?: string
  materialId: string
  doughId: string
  fillingId: string
  recipeId: string
  informationText?: string
  internalCapacityCount?: string
  internalCapacityUnit?: string
  numberItem?: string
  numberItemUnit?: string
  unitAdjustment?: number
  gramPerPiece?: string
  gramPerPieceUnit?: string
  gramPerPieceAdjustment?: number
}

const AddFormProductInformation: FC<Props> = (props) => {
  const dispatch = useDispatch<any>()
  const { formInfos, unit, errorText, handleProductInformation, noAddButton } =
    props

  const [formItems, setFormItems] = useState<ProductInformation[]>([])

  const handleSetFormItems = (newItems: ProductInformation[]) => {
    setFormItems(newItems)
    handleProductInformation(newItems)
  }

  const handleAddButton = () => {
    const newItem = {
      productName: '',
      manufacturerName: '',
      categoryName: '',
      middleKneadPercent: '',
      authenticKneadPercent: '',
      specifyByPiece: '',
      materialId: '',
      doughId: '',
      fillingId: '',
      recipeId: '',
      internalCapacityCount: '',
      internalCapacityUnit: '',
      numberItem: '',
      numberItemUnit: '',
      unitAdjustment: 1,
      gramPerPiece: '',
      gramPerPieceUnit: '',
      gramPerPieceAdjustment: 1,
    }
    const newItems = [...formItems, newItem]

    handleSetFormItems(newItems)
  }

  const handleDeleteButton = (index: number) => {
    const newItems = [...formItems]
    // 情報は1つは残す
    // if (newItems.length > 1) {
    //   newItems.splice(index, 1)
    //   handleSetFormItems(newItems)
    // }
    newItems.splice(index, 1)
    handleSetFormItems(newItems)
  }

  const handleChangeProductName = (index: number, productName: string) => {
    const newItems = [...formItems]
    newItems[index].productName = productName
    handleSetFormItems(newItems)
  }

  const handleChangeManufacturerName = (
    index: number,
    manufacturerName: string,
  ) => {
    const newItems = [...formItems]
    newItems[index].manufacturerName = manufacturerName
    handleSetFormItems(newItems)
  }

  const handleChangeMiddleKneadPercent = (
    index: number,
    middleKneadPercent: string,
  ) => {
    middleKneadPercent = middleKneadPercent ? middleKneadPercent : '0'
    // 半角数字に変換
    middleKneadPercent = middleKneadPercent
      .replace(/[０-９．]/g, function (s) {
        return String.fromCharCode(s.charCodeAt(0) - 65248)
      })
      .replace(/[-‐－―ー]/g, '')
      .replace(/[^\-\d\.]/g, '')
      .replace(/(?!^\-)[^\d\.]/g, '')
    // if (!Number(middleKneadPercent) && Number(middleKneadPercent) !== 0) {
    //   return
    // }
    // middleKneadPercent = String(Number(middleKneadPercent))
    const newItems = [...formItems]
    newItems[index].middleKneadPercent = middleKneadPercent
    newItems[index].specifyByPiece = ''
    handleSetFormItems(newItems)
  }

  const handleChangeAuthenticKneadPercent = (
    index: number,
    authenticKneadPercent: string,
  ) => {
    authenticKneadPercent = authenticKneadPercent ? authenticKneadPercent : '0'
    // 半角数字に変換
    authenticKneadPercent = authenticKneadPercent
      .replace(/[０-９．]/g, function (s) {
        return String.fromCharCode(s.charCodeAt(0) - 65248)
      })
      .replace(/[-‐－―ー]/g, '')
      .replace(/[^\-\d\.]/g, '')
      .replace(/(?!^\-)[^\d\.]/g, '')
    // if (!Number(authenticKneadPercent) && Number(authenticKneadPercent) !== 0) {
    //   return
    // }
    // authenticKneadPercent = String(Number(authenticKneadPercent))
    const newItems = [...formItems]
    newItems[index].authenticKneadPercent = authenticKneadPercent
    newItems[index].specifyByPiece = ''
    handleSetFormItems(newItems)
  }

  const handleChangeSpecifyByPiece = (
    index: number,
    specifyByPiece: string,
  ) => {
    specifyByPiece = specifyByPiece ? specifyByPiece : '0'
    // 半角数字に変換
    specifyByPiece = specifyByPiece
      .replace(/[０-９．]/g, function (s) {
        return String.fromCharCode(s.charCodeAt(0) - 65248)
      })
      .replace(/[-‐－―ー]/g, '')
      .replace(/[^\-\d\.]/g, '')
      .replace(/(?!^\-)[^\d\.]/g, '')
    const newItems = [...formItems]
    newItems[index].specifyByPiece = specifyByPiece

    // 中ごねをクリアし本ごねにもデータ登録する
    if (newItems[index].gramPerPiece && newItems[index].gramPerPieceUnit) {
      let unitAdjustment = 1
      if (
        newItems[index].gramPerPieceUnit &&
        newItems[index].gramPerPieceUnit === 'kg'
      ) {
        unitAdjustment = 1000
      }
      newItems[index].middleKneadPercent = ''
      newItems[index].authenticKneadPercent = String(
        Number(specifyByPiece) *
          Number(newItems[index].gramPerPiece) *
          unitAdjustment,
      )
    }
    handleSetFormItems(newItems)
  }

  const handleSuggest = async (
    info: suggestInfo,
    index: number | null | undefined,
  ) => {
    if (index != null) {
      const materialInfo = await dispatch(getMaterialById({ id: info.id }))
      if (materialInfo && materialInfo.payload) {
        const materialSubCategoryId: string = materialInfo.payload
          .material_sub_category_ref.id
          ? materialInfo.payload.material_sub_category_ref.id
          : // @ts-ignore
            materialInfo.payload.material_sub_category_ref._key.path.segments[6]
        const materialSubCategoryPayload = await dispatch(
          getMaterialSubCategoryById({ id: materialSubCategoryId }),
        )
        const materialSubCategory: MaterialSubCategoryType =
          materialSubCategoryPayload.payload
        const newItems = [...formItems]
        newItems[index].productName = materialInfo.payload.product_name
        newItems[index].manufacturerName = materialInfo.payload.manufacturer
        newItems[index].categoryName = materialSubCategory.name
        newItems[index].materialId = materialInfo.payload.id
        handleSetFormItems(newItems)
      }
    }
  }

  const getInformationText = async (
    materialInformation: MaterialInformationType,
    is_gram: boolean = false,
  ) => {
    let responseText = ''
    const unit = is_gram ? 'g' : '%'
    if (materialInformation.material_ref) {
      const materialId: string = materialInformation.material_ref.id
        ? materialInformation.material_ref.id
        : // @ts-ignore
          materialInformation.material_ref._key.path.segments[6]
      const materialPayload = await dispatch(
        getMaterialById({ id: materialId }),
      )
      const material: MaterialType = materialPayload.payload
      const product_name = material ? material.product_name : ''
      responseText +=
        '原材料名:' +
        product_name +
        ' 中捏:' +
        materialInformation.middle_knead_percent +
        unit +
        ' 本捏:' +
        materialInformation.authentic_knead_percent +
        unit +
        ' 計:' +
        String(
          Number(materialInformation.middle_knead_percent) +
            Number(materialInformation.authentic_knead_percent),
        ) +
        unit +
        '<br>'
    }

    if (materialInformation.dough_ref) {
      const doughId: string = materialInformation.dough_ref.id
        ? materialInformation.dough_ref.id
        : // @ts-ignore
          materialInformation.dough_ref._key.path.segments[6]
      const doughPayload = await dispatch(getDoughById({ id: doughId }))
      const dough: DoughType = doughPayload.payload
      const product_name = dough ? dough.pettern_name : ''
      responseText +=
        '生地パターン名:' +
        product_name +
        ' 中捏:' +
        materialInformation.middle_knead_percent +
        '%' +
        ' 本捏:' +
        materialInformation.authentic_knead_percent +
        '%' +
        ' 計:' +
        String(
          Number(materialInformation.middle_knead_percent) +
            Number(materialInformation.authentic_knead_percent),
        ) +
        '%<br>'
    }

    if (materialInformation.filling_ref) {
      const fillingId: string = materialInformation.filling_ref.id
        ? materialInformation.filling_ref.id
        : // @ts-ignore
          materialInformation.filling_ref._key.path.segments[6]
      const fillingPayload = await dispatch(getFillingById({ id: fillingId }))
      const filling: FillingType = fillingPayload.payload
      const product_name = filling ? filling.pettern_name : ''
      responseText +=
        'フィリングパターン名:' +
        product_name +
        ' 中捏:' +
        materialInformation.middle_knead_percent +
        'g' +
        ' 本捏:' +
        materialInformation.authentic_knead_percent +
        'g' +
        ' 計:' +
        String(
          Number(materialInformation.middle_knead_percent) +
            Number(materialInformation.authentic_knead_percent),
        ) +
        'g<br>'
    }
    return responseText
  }

  const initFormItem = async (formInfoData: ProductInformation[]) => {
    const formInfos = [...formInfoData]
    await Promise.all(
      formInfos.map(async (formInfo, index) => {
        formInfos[index].informationText = ''
        let infoTextList: string[] = []
        if (formInfo.doughId) {
          const doughPayload = await dispatch(
            getDoughById({ id: formInfo.doughId }),
          )
          const dough: DoughType = doughPayload.payload
          infoTextList.push(
            '生地パターン「' +
              dough.pettern_name +
              '」に含まれる原材料情報<br>',
          )
          await Promise.all(
            dough.material_informations.map(async (material_information) => {
              infoTextList.push(await getInformationText(material_information))
            }),
          )
          infoTextList.push('<br>')
        }
        if (formInfo.fillingId) {
          const fillingPayload = await dispatch(
            getFillingById({ id: formInfo.fillingId }),
          )
          const filling: FillingType = fillingPayload.payload
          infoTextList.push(
            'フィリングパターン「' +
              filling.pettern_name +
              '」に含まれる原材料情報<br>',
          )
          await Promise.all(
            filling.material_informations.map(async (material_information) => {
              infoTextList.push(
                await getInformationText(material_information, true),
              )
            }),
          )
          infoTextList.push('<br>')
        }
        if (formInfo.recipeId) {
          const recipePayload = await dispatch(
            getRecipeById({ id: formInfo.recipeId }),
          )
          const recipe: RecipeType = recipePayload.payload
          infoTextList.push(
            'レシピ「' + recipe.recipe_name + '」に含まれる原材料情報<br>',
          )
          await Promise.all(
            recipe.dough_material_informations.map(
              async (material_information) => {
                infoTextList.push(
                  await getInformationText(material_information),
                )
              },
            ),
          )
          await Promise.all(
            recipe.filling_material_informations.map(
              async (material_information) => {
                infoTextList.push(
                  await getInformationText(material_information),
                )
              },
            ),
          )
        }
        if (formInfo.materialId) {
          const materialCostPayload = await dispatch(
            getMaterialCostByMaterialId({ id: formInfo.materialId }),
          )
          const materialCost: MaterialCostType = materialCostPayload.payload
          if (materialCost && materialCost.suppliers) {
            materialCost.suppliers.forEach((supplier) => {
              if (supplier.used_costing) {
                formInfos[index].internalCapacityCount =
                  supplier.internal_capacity
                    ? String(supplier.internal_capacity.internal_capacity_count)
                    : String(supplier.internal_capacity_count)
                formInfos[index].internalCapacityUnit =
                  supplier.internal_capacity
                    ? String(supplier.internal_capacity.internal_capacity_unit)
                    : String(supplier.internal_capacity_unit)
                formInfos[index].numberItem = supplier.internal_capacity
                  ? String(supplier.internal_capacity.number_item)
                  : String(supplier.number_item)
                formInfos[index].numberItemUnit = supplier.internal_capacity
                  ? String(supplier.internal_capacity.number_item_unit)
                  : String(supplier.number_item_unit)
                let unitAdjustment = 1
                if (
                  formInfos[index].internalCapacityUnit &&
                  formInfos[index].internalCapacityUnit === 'kg'
                ) {
                  unitAdjustment = 1000
                } else if (
                  formInfos[index].internalCapacityUnit &&
                  formInfos[index].internalCapacityUnit === 'mL'
                ) {
                  unitAdjustment = 1
                } else if (
                  formInfos[index].internalCapacityUnit &&
                  formInfos[index].internalCapacityUnit === 'L'
                ) {
                  unitAdjustment = 1000
                }
                formInfos[index].unitAdjustment = unitAdjustment
                formInfos[index].gramPerPiece = supplier.gram_per_piece
                  ? String(supplier.gram_per_piece)
                  : ''
                formInfos[index].gramPerPieceUnit = supplier.gram_per_piece_unit
                  ? String(supplier.gram_per_piece_unit)
                  : ''
                let gramPerPieceAdjustment = 1
                if (
                  formInfos[index].gramPerPieceUnit &&
                  formInfos[index].gramPerPieceUnit === 'kg'
                ) {
                  gramPerPieceAdjustment = 1000
                }
                formInfos[index].gramPerPieceAdjustment = gramPerPieceAdjustment
              }
            })
          }
        }
        formInfos[index].informationText = infoTextList.join('<br>')
      }),
    )
    setFormItems(formInfos)
  }
  useEffect(() => {
    initFormItem(formInfos)
  }, [formInfos])

  const [viewToolTip, setViewToolTip] = useState<boolean[]>([])

  const handleMouseEnter = (index: number) => {
    const newViewToolTip = [...viewToolTip]
    newViewToolTip[index] = true
    setViewToolTip(newViewToolTip)
  }
  const handleMouseLeave = (index: number) => {
    const newViewToolTip = [...viewToolTip]
    newViewToolTip[index] = false
    setViewToolTip(newViewToolTip)
  }

  return (
    <div>
      <div className={styles.addform_error_text}>{errorText}</div>
      {formItems.map((item, index) => (
        <div className={styles.addform} key={index}>
          <InputTextSuggestWithLightLabel
            labelName="商品(材料名)"
            description=""
            line={1}
            type="text"
            size="semimiddle"
            value={item.productName}
            placeholder=""
            suggestType="material_product_name"
            index={index}
            onSelect={handleSuggest}
            onChange={(event) =>
              handleChangeProductName(index, event.target.value)
            }
            readOnly={
              item.materialId || item.doughId || item.fillingId || item.recipeId
                ? true
                : false
            }
          />
          <div
            className={styles.addform_pettern_info}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={() => handleMouseLeave(index)}
          >
            <img
              className={styles.addform_pettern_info_image}
              src={addFormInfo}
              alt="原材料確認"
            ></img>
          </div>
          {viewToolTip[index] && item.informationText ? (
            <div className={styles.addform_pettern_info_tool_tip}>
              {item.informationText.split('<br>').map((text) => (
                <div>{text}</div>
              ))}
            </div>
          ) : (
            ''
          )}
          <InputTextWithLightLabel
            labelName="メーカー"
            description=""
            line={1}
            type="text"
            size="small"
            value={item.manufacturerName}
            placeholder=""
            onChange={(event) =>
              handleChangeManufacturerName(index, event.target.value)
            }
            readOnly={
              item.materialId || item.doughId || item.fillingId || item.recipeId
                ? true
                : false
            }
          />
          <InputTextWithLightLabel
            labelName="カテゴリー"
            description=""
            line={1}
            type="text"
            size="small"
            value={item.categoryName}
            placeholder=""
            readOnly={true}
          />
          <InputTextAndUnitWithLightLabel
            labelName="中捏"
            description=""
            line={1}
            type="text"
            size="minimum"
            value={item.middleKneadPercent}
            placeholder=""
            unit={unit}
            onChange={(event) =>
              handleChangeMiddleKneadPercent(index, event.target.value)
            }
          />
          <InputTextAndUnitWithLightLabel
            labelName="本捏"
            description=""
            line={1}
            type="text"
            size="minimum"
            value={item.authenticKneadPercent}
            placeholder=""
            unit={unit}
            onChange={(event) =>
              handleChangeAuthenticKneadPercent(index, event.target.value)
            }
          />
          <InputTextAndUnitWithLightLabel
            labelName="個数にて指定"
            description=""
            line={1}
            type="text"
            size="minimum"
            value={item.specifyByPiece ? item.specifyByPiece : ''}
            placeholder=""
            unit="個"
            readOnly={!item.gramPerPiece || !item.gramPerPieceUnit}
            onChange={(event) =>
              handleChangeSpecifyByPiece(index, event.target.value)
            }
          />
          <InputTextAndUnitWithLightLabel
            labelName="計"
            description=""
            line={1}
            type="text"
            size="minimum"
            value={String(
              Number(item.middleKneadPercent) +
                Number(item.authenticKneadPercent),
            )}
            placeholder=""
            unit={unit}
            readOnly={true}
          />
          <div className={styles.addform_delete_button}>
            <SubmitButton
              label=""
              color="white"
              size="icon"
              icon="delete_orange"
              onClick={() => handleDeleteButton(index)}
            />
          </div>
          {/* {index > 0 ? (
            <div className={styles.addform_delete_button}>
              <SubmitButton
                label=""
                color="white"
                size="icon"
                icon="delete_orange"
                onClick={() => handleDeleteButton(index)}
              />
            </div>
          ) : (
            <div className={styles.addform_space_box_icon}></div>
          )} */}
        </div>
      ))}
      {!noAddButton ? (
        <div className={styles.addform_add_button}>
          <SubmitButton
            label="項目を増やす"
            color="white2"
            size="small"
            icon="outline_plus"
            onClick={handleAddButton}
          />
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default AddFormProductInformation
