import { FC, useState, useCallback, useEffect, MouseEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import styles from 'styles/components/template.module.scss'
import PageHedding from 'components/PageHedding/PageHedding'
import InputTextWithLightLabel from 'components/Form/WithLightLabel/InputText'
import SubmitButton from 'components/Button/SubmitButton'
import Table, { TableBody } from 'components/Table/Table'
import SelectCheckBox2WithLightLabel, {
  checkInfo,
} from 'components/Form/WithLightLabel/SelectCheckBox2'
import RadioButtonFilterWithLightLabel from 'components/Form/WithLightLabel/RadioButtonFilter'
import {
  getMaterials,
  MaterialType,
  getMaterialById,
  clearMaterial,
  deleteMaterial,
} from 'reducks/material/slice'
import { Allergens } from 'reducks/bread/slice'
import { getAllDoughs, DoughType } from 'reducks/dough/slice'
import { getAllFillings, FillingType } from 'reducks/filling/slice'
import { getAllPublicRecipes, RecipeType } from 'reducks/recipe/slice'
import { getShopById, ShopType } from 'reducks/shop/slice'
import { getAdditiveUses, AdditiveUseType } from 'reducks/additiveUse/slice'
import { getMaterialSubCategories } from 'reducks/materialSubCategory/slice'
import {
  getUsedBreadIdsRecursivelyByIds,
  DataType,
  UsedData,
} from 'reducks/business/slice'
import { RootState } from 'reducks/reducers'
import {
  getMaterialCostByMaterialIds,
  getMaterialCostByMaterialId,
  MaterialCostType,
  SupplierType,
} from 'reducks/materialCost/slice'
import { getMaterialCategories } from 'reducks/materialCategory/slice'
import { getAdditiveUseById } from 'reducks/additiveUse/slice'
import deleteConfirmImage from 'images/delete-confirm.png'
import ExcelJS from 'exceljs'
import { setIsLoading } from 'reducks/loading/slice'

interface CategoryMap {
  [prop: string]: string
}

interface DeleteConfirmShop {
  imagePath: string
  shopName: string
  doughNames: string
  fillingNames: string
  recipeNames: string
}

interface NameListMap {
  [id: string]: string[]
}

type CsvOutputMaterialCostInfo = {
  materialId: string
  productName: string
  manufacturer: string
  categoryName: string
  subCategoryName: string
  internalCapacityCount: string
  internalCapacityUnit: string
  numberItem: string
  numberItemUnit: string
  supplierCategory: string
  supplierCompanyName: string
  supplierBranchName: string
  costPrice: string
  costPriceIncludeTax: string
  tax: string
  ediblePart: string
  inEdiblePart: string
  yieldRate: string
  gramUnitPrice: string
  kilogramUnitPrice: string
  piecePerUnitPrice: string
  gramPerPiece: string
  energy?: string
  protein?: string
  lipid?: string
  carbohydrate?: string
  saltEquivalent?: string
  sourceInformation?: string
  url1?: string
  url2?: string
  url3?: string
  classifiedName?: string
  author?: string
  memo?: string
  allergens?: string
  materialType?: string
  materialName?: string
  rawMaterialName?: string
  additiveName?: string
}

const BreadDashBoardTemplate: FC = () => {
  const dispatch = useDispatch<any>()
  const navigate = useNavigate()
  const location = useLocation()
  const path = location.pathname
  const { staff, adminShopId } = useSelector(
    (state: RootState) => state.staffSlice,
  )
  const [materialList, setMaterialList] = useState<TableBody[][]>([])
  const [searchWord, setSearchWord] = useState<string>('')
  const [isPreview, setIsPreview] = useState(false)
  const [deleteId, setDeleteId] = useState<string | null>(null)
  const [deleteMaterialInfo, setDeleteMaterial] = useState<MaterialType | null>(
    null,
  )
  const [usedMaterialIds, setUsedMaterialIds] = useState<string[]>([])
  const [usedMaterial, setUsedMaterial] = useState<boolean>(false)
  const [notUsedMaterial, setNotUsedMaterial] = useState<boolean>(false)
  const [materialCostUsedMaterialIds, setMaterialCostUsedMaterialIds] =
    useState<string[]>([])
  const [materialCostWaitedMaterialIds, setMaterialCostWaitedMaterialIds] =
    useState<string[]>([])
  const [costNotInput, setCostNotInput] = useState<boolean>(false)
  const [costInputComplete, setCostInputComplete] = useState<boolean>(false)
  const [costInputWait, setCostInputWait] = useState<boolean>(false)
  const [materialCsvData, setMaterialCsvData] = useState<
    CsvOutputMaterialCostInfo[]
  >([])
  const headers = [
    {
      category: 'semi_long_name',
      elementInfo: {
        text: {
          label: '商品(材料)名',
        },
      },
    },
    {
      category: 'semi_long_name',
      elementInfo: {
        text: {
          label: 'メーカー',
        },
      },
    },
    {
      category: 'name',
      elementInfo: {
        text: {
          label: '大カテゴリー',
        },
      },
    },
  ]
  if (staff?.isAdmin) {
    headers.push({
      category: 'icon',
      elementInfo: {
        text: {
          label: '参照元',
        },
      },
    })
  }
  if (staff?.isAdmin === false || (staff?.isAdmin === true && adminShopId)) {
    headers.unshift({
      category: 'status',
      elementInfo: {
        text: {
          label: '原価入力状況',
        },
      },
    })
    headers.unshift({
      category: 'status',
      elementInfo: {
        text: {
          label: '原材料使用状況',
        },
      },
    })
    headers.push({
      category: 'icon',
      elementInfo: {
        text: {
          label: '原価入力',
        },
      },
    })
  }
  if (staff?.isAdmin) {
    headers.push({
      category: 'icon',
      elementInfo: {
        text: {
          label: '編集',
        },
      },
    })
    headers.push({
      category: 'icon',
      elementInfo: {
        text: {
          label: '削除',
        },
      },
    })
  }

  const handleSearchWord = (searchWord: string) => {
    setSearchWord(searchWord)
    filter({
      inputSearchWord: searchWord,
      inputCategories: categoryList,
      isUsedMaterial: usedMaterial,
      isNotUsedMaterial: notUsedMaterial,
      isCostInputComplete: costInputComplete,
      isCostInputWait: costInputWait,
      isCostNotInput: costNotInput,
    })
  }

  const handleCategoryCheck = (checkinfo: checkInfo[]) => {
    filter({
      inputSearchWord: searchWord,
      inputCategories: checkinfo,
      isUsedMaterial: usedMaterial,
      isNotUsedMaterial: notUsedMaterial,
      isCostInputComplete: costInputComplete,
      isCostInputWait: costInputWait,
      isCostNotInput: costNotInput,
    })
  }

  const handleAllUsedMaterial = () => {
    setUsedMaterial(false)
    setNotUsedMaterial(false)
    filter({
      inputSearchWord: searchWord,
      inputCategories: categoryList,
      isUsedMaterial: false,
      isNotUsedMaterial: false,
      isCostInputComplete: costInputComplete,
      isCostInputWait: costInputWait,
      isCostNotInput: costNotInput,
    })
  }

  const handleUsedMaterial = () => {
    setUsedMaterial(true)
    setNotUsedMaterial(false)
    filter({
      inputSearchWord: searchWord,
      inputCategories: categoryList,
      isUsedMaterial: true,
      isNotUsedMaterial: false,
      isCostInputComplete: costInputComplete,
      isCostInputWait: costInputWait,
      isCostNotInput: costNotInput,
    })
  }

  const handleNotUsedMaterial = () => {
    setUsedMaterial(false)
    setNotUsedMaterial(true)
    filter({
      inputSearchWord: searchWord,
      inputCategories: categoryList,
      isUsedMaterial: false,
      isNotUsedMaterial: true,
      isCostInputComplete: costInputComplete,
      isCostInputWait: costInputWait,
      isCostNotInput: costNotInput,
    })
  }

  const handleCostInputAll = () => {
    setCostInputComplete(false)
    setCostInputWait(false)
    setCostNotInput(false)
    filter({
      inputSearchWord: searchWord,
      inputCategories: categoryList,
      isUsedMaterial: usedMaterial,
      isNotUsedMaterial: notUsedMaterial,
      isCostInputComplete: false,
      isCostInputWait: false,
      isCostNotInput: false,
    })
  }

  const handleCostInputComplete = () => {
    setCostInputComplete(true)
    setCostInputWait(false)
    setCostNotInput(false)
    filter({
      inputSearchWord: searchWord,
      inputCategories: categoryList,
      isUsedMaterial: usedMaterial,
      isNotUsedMaterial: notUsedMaterial,
      isCostInputComplete: true,
      isCostInputWait: false,
      isCostNotInput: false,
    })
  }

  // const handleCostInputWait = () => {
  //   setCostInputComplete(false)
  //   setCostInputWait(true)
  //   setCostNotInput(false)
  //   filter({
  //     inputSearchWord: searchWord,
  //     inputCategories: categoryList,
  //     isUsedMaterial: usedMaterial,
  //     isNotUsedMaterial: notUsedMaterial,
  //     isCostInputComplete: false,
  //     isCostInputWait: true,
  //     isCostNotInput: false,
  //   })
  // }

  const handleCostNotInput = () => {
    setCostInputComplete(false)
    setCostInputWait(false)
    setCostNotInput(true)
    filter({
      inputSearchWord: searchWord,
      inputCategories: categoryList,
      isUsedMaterial: usedMaterial,
      isNotUsedMaterial: notUsedMaterial,
      isCostInputComplete: false,
      isCostInputWait: false,
      isCostNotInput: true,
    })
  }

  const handleIsPreview = (deleteId: string) => {
    // PCでのスクロール禁止
    document.addEventListener('mousewheel', scrollControl, { passive: false })
    // スマホでのタッチ操作でのスクロール禁止
    document.addEventListener('touchmove', scrollControl, { passive: false })
    setIsPreview(true)
    setDeleteId(deleteId)
  }
  const handleCloseIsPreview = () => {
    document.removeEventListener('mousewheel', scrollControl)
    document.removeEventListener('touchmove', scrollControl)
    setIsPreview(false)
    setDeleteId(null)
  }
  const scrollControl = useCallback((event: any) => {
    event.preventDefault()
  }, [])

  const { materialCategories } = useSelector(
    (state: RootState) => state.materialCategorySlice,
  )
  const { materialSubCategories } = useSelector(
    (state: RootState) => state.materialSubCategorySlice,
  )
  const [categoryList, setCategoryList] = useState<checkInfo[]>([])
  const createMaterialCategoryList = useCallback(async () => {
    await dispatch(getMaterialCategories())
    // const categoriesPayload: CategoryType[] = categories.payload
    const categoryList: checkInfo[] = []
    materialCategories.forEach((category) => {
      categoryList.push({
        isChecked: false,
        label: category.name,
        size: 'xlarge',
        type: 'default',
        filter: category.id,
      })
    })
    setCategoryList(categoryList)
  }, [dispatch, materialCategories])

  const { materials, material } = useSelector(
    (state: RootState) => state.materialSlice,
  )
  const dispatchGetmaterials = useCallback(async () => {
    // 使用できないプラン or スーパー管理者じゃなければ使用できない
    if (staff?.isStandardPlan || staff?.isAdmin === true) {
      //
    } else {
      navigate('/initload')
      window.scrollTo(0, 0)
    }
    await dispatch(getMaterials())
    await createMaterialCategoryList()
  }, [dispatch])
  const createMaterialList = useCallback(
    async (
      newMaterials: MaterialType[],
      inputUsedMaterialIds?: string[],
      inputMaterialCostUsedMaterialIds?: string[],
      inputMaterialCostWaitedMaterialIds?: string[],
    ) => {
      const localUsedMaterialIds = inputUsedMaterialIds
        ? inputUsedMaterialIds
        : usedMaterialIds
      const localMaterialCostUsedMaterialIds = inputMaterialCostUsedMaterialIds
        ? inputMaterialCostUsedMaterialIds
        : materialCostUsedMaterialIds
      const localMaterialCostWaitedMaterialIds =
        inputMaterialCostWaitedMaterialIds
          ? inputMaterialCostWaitedMaterialIds
          : materialCostWaitedMaterialIds
      // カテゴリのmap情報を作る
      const categoryMap: CategoryMap = {}
      materialCategories.forEach((category) => {
        categoryMap[category.id] = category.name
      })
      const materialList: TableBody[][] = []
      newMaterials.forEach((material) => {
        const categoryId = material.material_category_ref.id
          ? material.material_category_ref.id
          : // @ts-ignore
            material.material_category_ref._key.path.segments[6]
        let sourceInformationIcon = 'standards_doc_gray'
        if (material.source_information === 'standard') {
          sourceInformationIcon = 'standards_doc_gray'
        } else if (material.source_information === 'web') {
          sourceInformationIcon = 'web_gray'
        } else if (material.source_information === 'eight_orders') {
          sourceInformationIcon = 'nutrition_facts_gray'
        } else if (material.source_information === 'package') {
          sourceInformationIcon = 'package_gray'
        } else {
          sourceInformationIcon = 'standards_doc_gray'
        }
        const listColumns: TableBody[] = [
          {
            category: 'semi_long_name',
            active: true,
            elementInfo: {
              text: {
                label: material.product_name,
              },
            },
          },
          {
            category: 'semi_long_name',
            active: true,
            elementInfo: {
              text: {
                label: material.manufacturer,
              },
            },
          },
          {
            category: 'name',
            active: true,
            elementInfo: {
              text: {
                label: categoryMap[categoryId],
              },
            },
          },
        ]
        if (staff?.isAdmin) {
          listColumns.push({
            category: 'icon',
            active: true,
            elementInfo: {
              button: {
                label: '',
                color: 'white',
                size:
                  material.source_information === 'web'
                    ? 'icon'
                    : 'icon_unclickable',
                icon: sourceInformationIcon,
                to:
                  material.source_information === 'web' ? material.urls[0] : '',
              },
            },
          })
        }
        if (
          staff?.isAdmin === false ||
          (staff?.isAdmin === true && adminShopId)
        ) {
          listColumns.unshift({
            category: 'status',
            active: true,
            elementInfo: {
              status: {
                name: localMaterialCostUsedMaterialIds.includes(material.id)
                  ? '入力済'
                  : // TODO: ひとまずコメントアウトするが、しばらく利用されなければ項目関連するロジックも削除する
                    // : localMaterialCostWaitedMaterialIds.includes(material.id)
                    // ? '登録待ち'
                    '未入力',
                color: localMaterialCostUsedMaterialIds.includes(material.id)
                  ? 'red'
                  : localMaterialCostWaitedMaterialIds.includes(material.id)
                  ? 'blue'
                  : 'blue',
              },
            },
          })
          listColumns.unshift({
            category: 'status',
            active: true,
            elementInfo: {
              status: {
                name: localUsedMaterialIds.includes(material.id)
                  ? '使用中'
                  : '未使用',
                color: localUsedMaterialIds.includes(material.id)
                  ? 'red'
                  : 'gray',
              },
            },
          })
          listColumns.push({
            category: 'icon',
            active: true,
            elementInfo: {
              button: {
                label: '',
                color: 'white',
                size: 'icon',
                icon: 'cost_input_gray',
                to: '/material/' + material.id + '/cost',
              },
            },
          })
        }
        if (staff?.isAdmin) {
          listColumns.push({
            category: 'icon',
            active: true,
            elementInfo: {
              button: {
                label: '',
                color: 'white',
                size: 'icon',
                icon: 'edit_gray',
                to: '/material/edit/' + material.id,
              },
            },
          })
          listColumns.push({
            category: 'icon',
            active: true,
            elementInfo: {
              button: {
                label: '',
                color: 'white',
                size: 'icon',
                icon: 'delete_gray',
                deleteId: material.id,
              },
            },
          })
        }
        materialList.push(listColumns)
      })
      setMaterialList(materialList)
    },
    [materials],
  )
  const initFilter = useCallback(async () => {
    const materialsPayload = await dispatch(getMaterials())
    const materials: MaterialType[] = materialsPayload.payload
    // 使用中か否か情報を取得する
    const materialIds: string[] = materials.map((material) => {
      return material.id
    })
    const materialUsedInfosPayload = await dispatch(
      getUsedBreadIdsRecursivelyByIds({
        ids: materialIds,
        dataType: DataType.MATERIAL,
      }),
    )
    const materialUsedInfos: UsedData[] = materialUsedInfosPayload.payload
    const usedMaterialIds: string[] = materialUsedInfos.map(
      (materialUsedInfo) => {
        return materialUsedInfo.breadIdList.length > 0
          ? materialUsedInfo.id
          : ''
      },
    )
    setUsedMaterialIds(usedMaterialIds)
    const materialCostsPayload = await dispatch(
      getMaterialCostByMaterialIds({
        ids: materialIds,
      }),
    )
    const materialCosts: MaterialCostType[] = materialCostsPayload.payload
    const usedMaterialCosts = materialCosts.filter((materialCost) => {
      let waitStatus = false
      materialCost.suppliers.forEach((supplier) => {
        if (
          supplier.internal_capacity === null &&
          supplier.internal_capacity_count === undefined
        ) {
          waitStatus = true
        }
      })
      if (waitStatus) {
        return false
      }
      return true
    })
    const waitedMaterialCosts = materialCosts.filter((materialCost) => {
      let waitStatus = false
      materialCost.suppliers.forEach((supplier) => {
        if (
          supplier.internal_capacity === null &&
          supplier.internal_capacity_count === undefined
        ) {
          waitStatus = true
        }
      })
      if (waitStatus) {
        return true
      }
      return false
    })
    const materialCostUsedMaterialIds: string[] = usedMaterialCosts.map(
      (materialCost) => {
        return materialCost.material_ref.id
      },
    )
    const materialCostWaitedMaterialIds: string[] = waitedMaterialCosts.map(
      (materialCost) => {
        return materialCost.material_ref.id
      },
    )
    setMaterialCostUsedMaterialIds(materialCostUsedMaterialIds)
    setMaterialCostWaitedMaterialIds(materialCostWaitedMaterialIds)
    setUsedMaterial(true)
    setNotUsedMaterial(false)
    filter({
      inputSearchWord: '',
      inputCategories: [],
      isUsedMaterial: true,
      isNotUsedMaterial: false,
      isCostInputComplete: false,
      isCostInputWait: false,
      isCostNotInput: false,
      inputMaterials: materials,
      inputUsedMaterialIds: usedMaterialIds,
      inputMaterialCostUsedMaterialIds: materialCostUsedMaterialIds,
      inputMaterialCostWaitedMaterialIds: materialCostWaitedMaterialIds,
    })
  }, [])

  useEffect(() => {
    setDeleteMaterial(material)
  }, [material])

  useEffect(() => {
    if (!staff) {
      navigate('/initload?path=' + path)
      window.scrollTo(0, 0)
      return
    }
    dispatchGetmaterials()
    if (staff?.isAdmin && !adminShopId) {
      createMaterialList(materials)
    } else {
      initFilter()
    }
    // createMaterialList(materials)
  }, [])

  const filter = (payload: {
    inputSearchWord: string
    inputCategories: checkInfo[]
    isUsedMaterial: boolean
    isNotUsedMaterial: boolean
    isCostInputComplete: boolean
    isCostInputWait: boolean
    isCostNotInput: boolean
    inputMaterials?: MaterialType[]
    inputUsedMaterialIds?: string[]
    inputMaterialCostUsedMaterialIds?: string[]
    inputMaterialCostWaitedMaterialIds?: string[]
  }) => {
    let newMaterials = payload.inputMaterials
      ? [...payload.inputMaterials]
      : [...materials]
    const localUsedMaterialIds = payload.inputUsedMaterialIds
      ? payload.inputUsedMaterialIds
      : usedMaterialIds
    const localMaterialCostUsedMaterialIds =
      payload.inputMaterialCostUsedMaterialIds
        ? payload.inputMaterialCostUsedMaterialIds
        : materialCostUsedMaterialIds
    const localMaterialCostWaitedMaterialIds =
      payload.inputMaterialCostWaitedMaterialIds
        ? payload.inputMaterialCostWaitedMaterialIds
        : materialCostWaitedMaterialIds
    const newSearchWord = payload.inputSearchWord
    if (newSearchWord) {
      const newSearchWordHiragana = newSearchWord.replace(
        /[\u30a1-\u30f6]/g,
        (match) => {
          var chr = match.charCodeAt(0) - 0x60
          return String.fromCharCode(chr)
        },
      )
      const newSearchWordKatakana = newSearchWord.replace(
        /[\u3041-\u3096]/g,
        function (match) {
          var chr = match.charCodeAt(0) + 0x60
          return String.fromCharCode(chr)
        },
      )
      newMaterials = newMaterials.filter(
        (material) =>
          // ひらがな
          material.product_name
            .replace(/\s+/g, '')
            .toLowerCase()
            .indexOf(newSearchWordHiragana.replace(/\s+/g, '').toLowerCase()) >
            -1 ||
          // カタカナ
          material.product_name
            .replace(/\s+/g, '')
            .toLowerCase()
            .indexOf(newSearchWordKatakana.replace(/\s+/g, '').toLowerCase()) >
            -1 ||
          // ひらがな
          material.manufacturer
            .replace(/\s+/g, '')
            .toLowerCase()
            .indexOf(newSearchWordHiragana.replace(/\s+/g, '').toLowerCase()) >
            -1 ||
          // カタカナ
          material.manufacturer
            .replace(/\s+/g, '')
            .toLowerCase()
            .indexOf(newSearchWordKatakana.replace(/\s+/g, '').toLowerCase()) >
            -1 ||
          // ひらがな
          material.product_name_kana
            .replace(/\s+/g, '')
            .toLowerCase()
            .indexOf(newSearchWordHiragana.replace(/\s+/g, '').toLowerCase()) >
            -1 ||
          // カタカナ
          material.product_name_kana
            .replace(/\s+/g, '')
            .toLowerCase()
            .indexOf(newSearchWordKatakana.replace(/\s+/g, '').toLowerCase()) >
            -1 ||
          // ひらがな
          material.notation_fluctuation
            .join(',')
            .replace(/\s+/g, '')
            .toLowerCase()
            .indexOf(newSearchWordHiragana.replace(/\s+/g, '').toLowerCase()) >
            -1 ||
          // カタカナ
          material.notation_fluctuation
            .join(',')
            .replace(/\s+/g, '')
            .toLowerCase()
            .indexOf(newSearchWordKatakana.replace(/\s+/g, '').toLowerCase()) >
            -1,
      )
    }
    let newCategories = payload.inputCategories
    let isCheckCategory = false
    newCategories.forEach((category) => {
      if (category.isChecked) {
        isCheckCategory = true
      }
    })
    if (isCheckCategory) {
      newMaterials = newMaterials.filter((material) => {
        let hit = false
        newCategories.forEach((category) => {
          if (
            category.isChecked &&
            material.material_category_ref.id === category.filter
          ) {
            hit = true
          }
        })
        return hit
      })
    }
    const isUsedMaterial = payload.isUsedMaterial
    const isNotUsedMaterial = payload.isNotUsedMaterial
    if (isUsedMaterial || isNotUsedMaterial) {
      if (isUsedMaterial) {
        newMaterials = newMaterials.filter((material) => {
          let hit = false
          if (localUsedMaterialIds.includes(material.id)) {
            hit = true
          }
          return hit
        })
      }
      if (isNotUsedMaterial) {
        newMaterials = newMaterials.filter((material) => {
          let hit = true
          if (localUsedMaterialIds.includes(material.id)) {
            hit = false
          }
          return hit
        })
      }
    }
    const isCostInputComplete = payload.isCostInputComplete
    const isCostInputWait = payload.isCostInputWait
    const isCostNotInput = payload.isCostNotInput
    if (isCostInputComplete || isCostInputWait || isCostNotInput) {
      if (isCostInputComplete) {
        newMaterials = newMaterials.filter((material) => {
          let hit = false
          if (localMaterialCostUsedMaterialIds.includes(material.id)) {
            hit = true
          }
          return hit
        })
      }
      if (isCostInputWait) {
        newMaterials = newMaterials.filter((material) => {
          let hit = false
          if (localMaterialCostWaitedMaterialIds.includes(material.id)) {
            hit = true
          }
          return hit
        })
      }
      if (isCostNotInput) {
        newMaterials = newMaterials.filter((material) => {
          let hit = true
          if (
            localMaterialCostUsedMaterialIds.includes(material.id) ||
            localMaterialCostWaitedMaterialIds.includes(material.id)
          ) {
            hit = false
          }
          return hit
        })
      }
    }
    createMaterialList(
      newMaterials,
      localUsedMaterialIds,
      localMaterialCostUsedMaterialIds,
      localMaterialCostWaitedMaterialIds,
    )
  }

  const handleSubmmit = async (
    to: string | undefined,
    deleteId: string | undefined,
  ) => {
    if (to) {
      const id = to.split('/material/edit/')[1]
        ? to.split('/material/edit/')[1]
        : to.split('/material/')[1].split('/cost')[0]
        ? to.split('/material/')[1].split('/cost')[0]
        : null
      if (id) {
        await dispatch(getAdditiveUses())
        await dispatch(getMaterialById({ id: id }))
        await dispatch(getMaterialCategories())
        await dispatch(getMaterialSubCategories())
        navigate(to)
      } else {
        window.open(to, '_blank')
      }
    }
    if (deleteId) {
      const deleteConfirmShopList = await setDeleteConfirmMaterialInfo(deleteId)
      if (deleteConfirmShopList.length) {
        navigate('/material/delete/' + deleteId + '/confirm')
      } else {
        await dispatch(getMaterialById({ id: deleteId }))
        handleIsPreview(deleteId)
      }
    }
  }

  const handleDelete = async () => {
    if (deleteId) {
      await dispatch(deleteMaterial({ id: deleteId }))
      const materials = await dispatch(getMaterials())
      await createMaterialCategoryList()
      // await dispatchGetmaterials()
      await createMaterialList(materials.payload)
      handleCloseIsPreview()
    }
  }

  const toAddPage = async () => {
    await dispatch(clearMaterial())
    await dispatch(getMaterialCategories())
    await dispatch(getMaterialSubCategories())
    navigate('/material/add')
  }

  // TODO: 生地とフィリングの使用不可処理とともに共通処理化したい
  const setDeleteConfirmMaterialInfo = useCallback(
    async (materialId: string | null) => {
      if (!materialId) {
        return []
      }
      dispatch(setIsLoading({ isLoading: true }))
      const confirmShopIdList: string[] = []
      const fillingsPayload = await dispatch(getAllFillings())
      const fillings: FillingType[] = fillingsPayload.payload
      const fillingsWaitList: FillingType[] = []
      fillings.forEach((filling) => {
        if (filling.material_informations) {
          filling.material_informations.forEach((material_information) => {
            if (
              material_information.material_ref &&
              material_information.material_ref.id === materialId
            ) {
              fillingsWaitList.push(filling)
            }
          })
        }
      })
      const fillingNameList: NameListMap = {}
      fillingsWaitList.forEach((filling) => {
        if (!confirmShopIdList.includes(filling.shop_ref.id)) {
          confirmShopIdList.push(filling.shop_ref.id)
        }
        if (fillingNameList.hasOwnProperty(filling.shop_ref.id)) {
          fillingNameList[filling.shop_ref.id].push(filling.pettern_name)
        } else {
          fillingNameList[filling.shop_ref.id] = [filling.pettern_name]
        }
      })
      const doughsPayload = await dispatch(getAllDoughs())
      const doughs: DoughType[] = doughsPayload.payload
      const doughsWaitList: DoughType[] = []
      doughs.forEach((dough) => {
        if (dough.material_informations) {
          dough.material_informations.forEach((material_information) => {
            if (
              material_information.material_ref &&
              material_information.material_ref.id === materialId
            ) {
              doughsWaitList.push(dough)
            }
          })
        }
      })
      const doughNameList: NameListMap = {}
      doughsWaitList.forEach((dough) => {
        if (!confirmShopIdList.includes(dough.shop_ref.id)) {
          confirmShopIdList.push(dough.shop_ref.id)
        }
        if (doughNameList.hasOwnProperty(dough.shop_ref.id)) {
          doughNameList[dough.shop_ref.id].push(dough.pettern_name)
        } else {
          doughNameList[dough.shop_ref.id] = [dough.pettern_name]
        }
      })
      const recipesPayload = await dispatch(getAllPublicRecipes())
      const recipes: RecipeType[] = recipesPayload.payload
      const recipesWaitList: RecipeType[] = []
      if (recipes) {
        recipes.forEach((recipe) => {
          if (recipe.dough_material_informations) {
            recipe.dough_material_informations.forEach(
              (material_information) => {
                if (
                  material_information.material_ref &&
                  material_information.material_ref.id === materialId
                ) {
                  recipesWaitList.push(recipe)
                }
              },
            )
          }
          if (recipe.filling_material_informations) {
            recipe.filling_material_informations.forEach(
              (material_information) => {
                if (
                  material_information.material_ref &&
                  material_information.material_ref.id === materialId
                ) {
                  recipesWaitList.push(recipe)
                }
              },
            )
          }
        })
      }
      const recipeNameList: NameListMap = {}
      recipesWaitList.forEach((recipe) => {
        if (!confirmShopIdList.includes(recipe.shop_ref.id)) {
          confirmShopIdList.push(recipe.shop_ref.id)
        }
        if (recipeNameList.hasOwnProperty(recipe.shop_ref.id)) {
          recipeNameList[recipe.shop_ref.id].push(recipe.recipe_name)
        } else {
          recipeNameList[recipe.shop_ref.id] = [recipe.recipe_name]
        }
      })

      const deleteConfirmShopList: DeleteConfirmShop[] = []
      await Promise.all(
        confirmShopIdList.map(async (confirmShopId) => {
          const shopPayload = await dispatch(getShopById({ id: confirmShopId }))
          const shop: ShopType = shopPayload.payload
          if (shop) {
            deleteConfirmShopList.push({
              shopName: shop.name,
              imagePath: '',
              doughNames: doughNameList[confirmShopId]
                ? doughNameList[confirmShopId].join(',')
                : '',
              fillingNames: fillingNameList[confirmShopId]
                ? fillingNameList[confirmShopId].join(',')
                : '',
              recipeNames: recipeNameList[confirmShopId]
                ? recipeNameList[confirmShopId].join(',')
                : '',
            })
          }
        }),
      )
      dispatch(setIsLoading({ isLoading: false }))

      return deleteConfirmShopList
    },
    [],
  )

  const initCsvData = useCallback(async () => {
    const materialIds: string[] = materials.map((material) => {
      return material.id
    })
    const materialUsedInfosPayload = await dispatch(
      getUsedBreadIdsRecursivelyByIds({
        ids: materialIds,
        dataType: DataType.MATERIAL,
      }),
    )
    const materialUsedInfos: UsedData[] = materialUsedInfosPayload.payload
    const usedMaterialIds: string[] = materialUsedInfos.map(
      (materialUsedInfo) => {
        return materialUsedInfo.breadIdList.length > 0
          ? materialUsedInfo.id
          : ''
      },
    )

    const csvRows: CsvOutputMaterialCostInfo[] = []
    await Promise.all(
      usedMaterialIds.map(async (materialId: string) => {
        if (!materialId) {
          return
        }
        const materialPayload = await dispatch(
          getMaterialById({ id: materialId }),
        )
        const material: MaterialType = materialPayload.payload
        const materialCostPayload = await dispatch(
          getMaterialCostByMaterialId({ id: materialId }),
        )
        const materialCost: MaterialCostType = materialCostPayload.payload
        const usedCategories = materialCategories.filter((materialCategory) => {
          if (materialCategory.id === material.material_category_ref.id) {
            return true
          }
          return false
        })
        let categoryName = ''
        if (usedCategories.length > 0) {
          categoryName = usedCategories[0].name
        }
        const usedSubCategories = materialSubCategories.filter(
          (materialSubCategory) => {
            if (
              materialSubCategory.id === material.material_sub_category_ref.id
            ) {
              return true
            }
            return false
          },
        )
        let subCategoryName = ''
        if (usedSubCategories.length > 0) {
          subCategoryName = usedSubCategories[0].name
        }
        let supplier: SupplierType | null = null
        let unitAdjustment = 1
        let gramPerPieceAdjustment = 1
        if (materialCost) {
          const usedSuppliers = materialCost.suppliers.filter((supplier) => {
            if (supplier.used_costing) {
              return true
            }
            return false
          })
          if (usedSuppliers.length > 0) {
            supplier = usedSuppliers[0]
            if (
              (supplier.internal_capacity_unit &&
                supplier.internal_capacity_unit === 'kg') ||
              (supplier.internal_capacity &&
                supplier.internal_capacity.internal_capacity_unit &&
                supplier.internal_capacity.internal_capacity_unit === 'kg')
            ) {
              unitAdjustment = 1000
            } else if (
              (supplier.internal_capacity_unit &&
                supplier.internal_capacity_unit === 'mL') ||
              (supplier.internal_capacity &&
                supplier.internal_capacity.internal_capacity_unit &&
                supplier.internal_capacity.internal_capacity_unit === 'mL')
            ) {
              unitAdjustment = 1
            } else if (
              (supplier.internal_capacity_unit &&
                supplier.internal_capacity_unit === 'L') ||
              (supplier.internal_capacity &&
                supplier.internal_capacity.internal_capacity_unit &&
                supplier.internal_capacity.internal_capacity_unit === 'L')
            ) {
              unitAdjustment = 1000
            }
            if (
              supplier.gram_per_piece_unit &&
              supplier.gram_per_piece_unit === 'kg'
            ) {
              gramPerPieceAdjustment = 1000
            }
          }
        }
        const row: CsvOutputMaterialCostInfo = {
          materialId: materialId,
          productName: material ? material.product_name : '',
          manufacturer: material ? material.manufacturer : '',
          categoryName: categoryName,
          subCategoryName: subCategoryName,
          internalCapacityCount:
            supplier && supplier.internal_capacity_count
              ? String(supplier.internal_capacity_count)
              : supplier &&
                supplier.internal_capacity &&
                supplier.internal_capacity.internal_capacity_count
              ? String(supplier.internal_capacity.internal_capacity_count)
              : '',
          internalCapacityUnit:
            supplier && supplier.internal_capacity_unit
              ? supplier.internal_capacity_unit
              : supplier &&
                supplier.internal_capacity &&
                supplier.internal_capacity.internal_capacity_unit
              ? supplier.internal_capacity.internal_capacity_unit
              : '',
          numberItem:
            supplier && supplier.number_item
              ? String(supplier.number_item)
              : supplier &&
                supplier.internal_capacity &&
                supplier.internal_capacity.number_item
              ? String(supplier.internal_capacity.number_item)
              : '',
          numberItemUnit:
            supplier && supplier.number_item_unit
              ? supplier.number_item_unit
              : supplier &&
                supplier.internal_capacity &&
                supplier.internal_capacity.number_item_unit
              ? supplier.internal_capacity.number_item_unit
              : '',
          supplierCategory:
            supplier &&
            supplier.supplier_category &&
            supplier.supplier_category === 'wholesale'
              ? '問屋'
              : supplier &&
                supplier.supplier_category &&
                supplier.supplier_category === 'direct'
              ? '直販'
              : supplier &&
                supplier.supplier_category &&
                supplier.supplier_category === 'other'
              ? 'その他'
              : '',
          supplierCompanyName: supplier ? supplier.supplier_company_name : '',
          supplierBranchName: supplier ? supplier.supplier_branch_name : '',
          costPrice: supplier ? String(supplier.cost_price) : '',
          costPriceIncludeTax: supplier
            ? String(
                (supplier.cost_price * (1 + supplier.tax / 100)).toFixed(2),
              )
            : '',
          tax: supplier ? String(supplier.tax) : '',
          ediblePart:
            materialCost && materialCost.edible_part
              ? String(materialCost.edible_part)
              : '',
          inEdiblePart:
            materialCost && materialCost.in_edible_part
              ? String(materialCost.in_edible_part)
              : '',
          yieldRate:
            materialCost && materialCost.yield_rate
              ? String(materialCost.yield_rate)
              : '',
          gramUnitPrice:
            supplier &&
            supplier.internal_capacity_count &&
            supplier.number_item &&
            materialCost
              ? String(
                  (
                    (Number(supplier.cost_price) * (supplier.tax / 100 + 1)) /
                    (materialCost.yield_rate / 100) /
                    Number(supplier.internal_capacity_count) /
                    Number(supplier.number_item) /
                    unitAdjustment
                  ).toFixed(2),
                )
              : supplier &&
                supplier.internal_capacity &&
                supplier.internal_capacity.internal_capacity_count &&
                supplier.internal_capacity.number_item &&
                materialCost
              ? String(
                  (
                    (Number(supplier.cost_price) * (supplier.tax / 100 + 1)) /
                    (materialCost.yield_rate / 100) /
                    Number(supplier.internal_capacity.internal_capacity_count) /
                    Number(supplier.internal_capacity.number_item) /
                    unitAdjustment
                  ).toFixed(2),
                )
              : '',
          kilogramUnitPrice:
            supplier &&
            supplier.internal_capacity_count &&
            supplier.number_item &&
            materialCost
              ? String(
                  (
                    ((Number(supplier.cost_price) * (supplier.tax / 100 + 1)) /
                      (materialCost.yield_rate / 100) /
                      Number(supplier.internal_capacity_count) /
                      Number(supplier.number_item) /
                      unitAdjustment) *
                    1000
                  ).toFixed(2),
                )
              : supplier &&
                supplier.internal_capacity &&
                supplier.internal_capacity.internal_capacity_count &&
                supplier.internal_capacity.number_item &&
                materialCost
              ? String(
                  (
                    ((Number(supplier.cost_price) * (supplier.tax / 100 + 1)) /
                      (materialCost.yield_rate / 100) /
                      Number(
                        supplier.internal_capacity.internal_capacity_count,
                      ) /
                      Number(supplier.internal_capacity.number_item) /
                      unitAdjustment) *
                    1000
                  ).toFixed(2),
                )
              : '',
          gramPerPiece:
            supplier && supplier.gram_per_piece
              ? String(supplier.gram_per_piece * gramPerPieceAdjustment)
              : '',
          piecePerUnitPrice:
            supplier &&
            supplier.internal_capacity_count &&
            supplier.number_item &&
            supplier.gram_per_piece &&
            materialCost
              ? String(
                  (
                    ((Number(supplier.cost_price) * (supplier.tax / 100 + 1)) /
                      (materialCost.yield_rate / 100) /
                      Number(supplier.internal_capacity_count) /
                      Number(supplier.number_item) /
                      unitAdjustment) *
                    (Number(supplier.gram_per_piece) * gramPerPieceAdjustment)
                  ).toFixed(2),
                )
              : '',
        }
        if (staff?.isAdmin) {
          // スーパー管理者の場合にのみ出力する項目
          row.energy = material ? String(material.energy) : ''
          row.protein = material ? String(material.protein) : ''
          row.lipid = material ? String(material.lipid) : ''
          row.carbohydrate = material ? String(material.carbohydrate) : ''
          row.saltEquivalent = material ? String(material.salt_equivalent) : ''
          row.sourceInformation =
            material && material.source_information === 'standard'
              ? '規格書'
              : material && material.source_information === 'web'
              ? 'Web'
              : material && material.source_information === 'eight_orders'
              ? '日本食品標準成分表2020年版 八訂'
              : material && material.source_information === 'package'
              ? 'パッケージ'
              : ''
          row.url1 =
            material && material.urls.length > 0 ? material.urls[0] : ''
          row.url2 =
            material && material.urls.length > 1 ? material.urls[1] : ''
          row.url3 =
            material && material.urls.length > 2 ? material.urls[2] : ''
          row.classifiedName =
            material && material.classified_name ? material.classified_name : ''
          row.author = material && material.author ? material.author : ''
          row.memo = material && material.memo ? material.memo : ''
          const allergens = material
            ? new Set([
                ...material.base_allergens,
                ...material.additive_allergens,
              ])
            : new Set()
          let allergensText = new Set()
          if (allergens.has(Allergens.EGG)) {
            allergensText.add('卵')
          }
          if (allergens.has(Allergens.MILK)) {
            allergensText.add('乳')
          }
          if (allergens.has(Allergens.WHEAT)) {
            allergensText.add('小麦')
          }
          if (allergens.has(Allergens.SHRIMP)) {
            allergensText.add('えび')
          }
          if (allergens.has(Allergens.CRUB)) {
            allergensText.add('かに')
          }
          if (allergens.has(Allergens.PEANUTS)) {
            allergensText.add('落花生')
          }
          if (allergens.has(Allergens.SOBA)) {
            allergensText.add('そば')
          }
          if (allergens.has(Allergens.ALMOND)) {
            allergensText.add('アーモンド')
          }
          if (allergens.has(Allergens.ABALONE)) {
            allergensText.add('あわび')
          }
          if (allergens.has(Allergens.SQUID)) {
            allergensText.add('いか')
          }
          if (allergens.has(Allergens.IKURA)) {
            allergensText.add('いくら')
          }
          if (allergens.has(Allergens.ORANGE)) {
            allergensText.add('オレンジ')
          }
          if (allergens.has(Allergens.CASHEWNUTS)) {
            allergensText.add('カシューナッツ')
          }
          if (allergens.has(Allergens.KIWIFRUIT)) {
            allergensText.add('キウイフルーツ')
          }
          if (allergens.has(Allergens.BEEF)) {
            allergensText.add('牛肉')
          }
          if (allergens.has(Allergens.WALNUT)) {
            allergensText.add('くるみ')
          }
          if (allergens.has(Allergens.SESAME)) {
            allergensText.add('ごま')
          }
          if (allergens.has(Allergens.SALMON)) {
            allergensText.add('鮭')
          }
          if (allergens.has(Allergens.MACKEREL)) {
            allergensText.add('鯖')
          }
          if (allergens.has(Allergens.SOY)) {
            allergensText.add('大豆')
          }
          if (allergens.has(Allergens.CHICKEN)) {
            allergensText.add('鶏肉')
          }
          if (allergens.has(Allergens.BANANA)) {
            allergensText.add('バナナ')
          }
          if (allergens.has(Allergens.PORK)) {
            allergensText.add('豚肉')
          }
          if (allergens.has(Allergens.MACADAMIANUTS)) {
            allergensText.add('マカダミアナッツ')
          }
          if (allergens.has(Allergens.PEACH)) {
            allergensText.add('桃')
          }
          if (allergens.has(Allergens.YAM)) {
            allergensText.add('山芋')
          }
          if (allergens.has(Allergens.APPLE)) {
            allergensText.add('りんご')
          }
          if (allergens.has(Allergens.GELATINE)) {
            allergensText.add('ゼラチン')
          }
          row.allergens = [...allergensText].join(',')
          row.materialType =
            material && material.single_material
              ? '単一原材料'
              : material && material.composite_material
              ? '複合原材料'
              : 'なし'
          row.materialName =
            material && material.single_material
              ? material.material_name
              : material && material.composite_material
              ? material.composite_material_name
              : ''
          const constituteMaterialNames: string[] = []
          if (material && material.constitute_material.length > 0) {
            material.constitute_material.forEach((ConstituteMaterial) => {
              constituteMaterialNames.push(ConstituteMaterial.material_name)
            })
          }
          row.rawMaterialName =
            material && material.single_material
              ? material.raw_material_name
              : material &&
                material.composite_material &&
                material.constitute_material.length > 0
              ? constituteMaterialNames.join(',')
              : ''
          const additiveNames: string[] = []
          if (material && material.additive.length > 0) {
            await Promise.all(
              material.additive.map(async (additive) => {
                let additiveUse: AdditiveUseType | null = null
                if (additive.additive_use_ref) {
                  const additiveUsePayload = await dispatch(
                    getAdditiveUseById({ id: additive.additive_use_ref.id }),
                  )
                  additiveUse = additiveUsePayload.payload
                }
                // if (additive.additive_material && additiveUse) {
                //   if (additive.disabled_material_name || additiveUse.disabled_material_name) {
                //     // 物質名は表示しない
                //     additiveNames.push(additiveUse.name)
                //   } else {
                //     additiveNames.push(additiveUse.name + "(" + additive.additive_material + ")")
                //   }
                // } else if (additive.additive_material && !additive.disabled_material_name) {
                //   additiveNames.push(additive.additive_material)
                // } else if (additiveUse && !additiveUse.disabled_material_name) {
                //   additiveNames.push(additiveUse.name)
                // }
                if (additive.additive_material && additiveUse) {
                  additiveNames.push(
                    additiveUse.name + '(' + additive.additive_material + ')',
                  )
                } else if (additive.additive_material) {
                  additiveNames.push(additive.additive_material)
                } else if (additiveUse) {
                  additiveNames.push(additiveUse.name)
                }
              }),
            )
          }
          row.additiveName = additiveNames.join(',')
        }
        csvRows.push(row)
      }),
    )
    setMaterialCsvData(csvRows)
  }, [usedMaterialIds])

  useEffect(() => {
    initCsvData()
  }, [usedMaterialIds])

  const handlerCsvDownloadButton = async (
    e:
      | MouseEvent<HTMLButtonElement, MouseEvent>
      | MouseEvent<HTMLButtonElement>
      | undefined,
    format: 'xlsx' | 'csv',
  ) => {
    if (!e) {
      return
    }
    e.preventDefault()

    const workbook = new ExcelJS.Workbook()
    workbook.addWorksheet('sheet1')
    const worksheet = workbook.getWorksheet('sheet1')

    const columns = [
      { header: 'ID', key: 'materialId' },
      { header: '材料名', key: 'productName' },
      { header: 'メーカー', key: 'manufacturer' },
      { header: '大カテゴリー', key: 'categoryName' },
      { header: '小カテゴリー', key: 'subCategoryName' },
      { header: '内容量', key: 'internalCapacityCount' },
      { header: '内容量(単位)', key: 'internalCapacityUnit' },
      { header: '入数', key: 'numberItem' },
      { header: '入数(単位)', key: 'numberItemUnit' },
      { header: '仕入れ先カテゴリー', key: 'supplierCategory' },
      { header: '仕入先会社名', key: 'supplierCompanyName' },
      { header: '仕入先支社名', key: 'supplierBranchName' },
      { header: '仕入れ値(税抜)', key: 'costPrice' },
      { header: '仕入れ値(税込)', key: 'costPriceIncludeTax' },
      { header: '仕入れ値税率(%)', key: 'tax' },
      { header: '可食部(g)', key: 'ediblePart' },
      { header: '不可食部(g)', key: 'inEdiblePart' },
      { header: '可食部率(%)', key: 'yieldRate' },
      { header: 'g単価', key: 'gramUnitPrice' },
      { header: 'kg単価', key: 'kilogramUnitPrice' },
      { header: '1個あたりの重量(g)', key: 'gramPerPiece' },
      { header: '1個単価', key: 'piecePerUnitPrice' },
    ]

    if (staff?.isAdmin) {
      // スーパー管理者の場合にのみ出力する項目
      columns.push({ header: '原材料種類', key: 'materialType' })
      columns.push({ header: '名称', key: 'materialName' })
      columns.push({ header: '構成原材料名', key: 'rawMaterialName' })
      columns.push({ header: '添加物', key: 'additiveName' })
      columns.push({ header: 'アレルゲン(特定原材料)', key: 'allergens' })
      columns.push({ header: 'エネルギー(kcal)', key: 'energy' })
      columns.push({ header: 'タンパク質(g)', key: 'protein' })
      columns.push({ header: '脂質(g)', key: 'lipid' })
      columns.push({ header: '炭水化物(g)', key: 'carbohydrate' })
      columns.push({ header: '食塩相当量(g)', key: 'saltEquivalent' })
      columns.push({ header: '情報元', key: 'sourceInformation' })
      columns.push({ header: 'URL1', key: 'url1' })
      columns.push({ header: 'URL2', key: 'url2' })
      columns.push({ header: 'URL3', key: 'url3' })
      columns.push({ header: '分類名', key: 'classifiedName' })
      columns.push({ header: '登録者', key: 'author' })
      columns.push({ header: 'メモ', key: 'memo' })
    }
    worksheet.columns = columns

    worksheet.addRows(materialCsvData)

    const uint8Array =
      format === 'xlsx'
        ? await workbook.xlsx.writeBuffer()
        : await workbook.csv.writeBuffer()
    const bom = new Uint8Array([0xef, 0xbb, 0xbf])
    const blob = new Blob([bom, uint8Array], {
      type: 'application/octet-binary',
    })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = '使用中原材料.' + format
    a.click()
    a.remove()
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.dashboardPageHeadContainer}>
          <PageHedding title="原材料一覧" to="" />
          <div>
            {staff?.isAdmin ? (
              <SubmitButton
                label="新規原材料を登録する"
                color="orange"
                size="xlarge"
                icon="plus"
                onClick={() => toAddPage()}
              />
            ) : (
              ''
            )}
          </div>
        </div>
        <div className={styles.pattern}>
          <div className={styles.pattern_head}>
            <InputTextWithLightLabel
              labelName="フリーワード検索"
              description=""
              line={1}
              type="text"
              size="middle"
              value={searchWord}
              placeholder="検索ワードを入力"
              onChange={(event) => handleSearchWord(event.target.value)}
            />
            <div className={styles.pattern_category_search}>
              <SelectCheckBox2WithLightLabel
                labelName="商品カテゴリーを選ぶ"
                description=""
                line={1}
                size="xlarge"
                onBlur={() => {}}
                onFocus={() => {}}
                checkInfos={categoryList}
                onCheck={handleCategoryCheck}
              />
            </div>
          </div>
          {staff?.isAdmin === false ||
          (staff?.isAdmin === true && adminShopId) ? (
            <div className={styles.pattern_head}>
              <RadioButtonFilterWithLightLabel
                labelName="原材料使用状況"
                description=""
                line={1}
                radioSize="small"
                flex={true}
                isChecked={!usedMaterial && !notUsedMaterial}
                onChange={handleAllUsedMaterial}
                checkInfos={[
                  {
                    isChecked: usedMaterial,
                    label: '使用中',
                    onChange: handleUsedMaterial,
                  },
                  {
                    isChecked: notUsedMaterial,
                    label: '未使用',
                    onChange: handleNotUsedMaterial,
                  },
                ]}
              />
              <div className={styles.dashboardSearchRowSection_middle}></div>
              <RadioButtonFilterWithLightLabel
                labelName="原価入力状況"
                description=""
                line={1}
                radioSize="small"
                flex={true}
                isChecked={
                  !costInputComplete && !costInputWait && !costNotInput
                }
                onChange={handleCostInputAll}
                checkInfos={[
                  {
                    isChecked: costInputComplete,
                    label: '入力済',
                    onChange: handleCostInputComplete,
                  },
                  // TODO: ひとまずコメントアウトするが、しばらく利用されなければ項目関連するロジックも削除する
                  // {
                  //   isChecked: costInputWait,
                  //   label: '登録待ち',
                  //   onChange: handleCostInputWait,
                  // },
                  {
                    isChecked: costNotInput,
                    label: '未入力',
                    onChange: handleCostNotInput,
                  },
                ]}
              />
              <div className={styles.pattern_margin_large}></div>
            </div>
          ) : (
            ''
          )}
          <div className={styles.pattern_table}>
            <Table
              headers={headers}
              bodies={materialList}
              handleSubmmit={handleSubmmit}
            />
          </div>
          <div style={{ marginTop: 32 }}>
            <SubmitButton
              label="使用中原材料をCSV形式で書き出す"
              color="orange"
              size="large"
              icon="none"
              onClick={(e) => handlerCsvDownloadButton(e, 'csv')}
            />
          </div>
        </div>
      </div>
      {isPreview && (
        <div className={styles.modal_material_delete}>
          <img
            className={styles.modal_material_delete_image_confirm}
            src={deleteConfirmImage}
            alt="削除確認"
          ></img>
          <div className={styles.modal_material_delete_text_message}>
            {deleteMaterialInfo ? deleteMaterialInfo.product_name : ''}
            を削除しますか？
          </div>
          <div className={styles.modalButtonContainer}>
            <SubmitButton
              label="キャンセルする"
              color="orange_outlined"
              size="xmiddle"
              icon="none"
              onClick={handleCloseIsPreview}
            />
            <div style={{ marginLeft: 32 }}>
              <SubmitButton
                label="削除する"
                color="orange"
                size="xmiddle"
                icon="none"
                onClick={handleDelete}
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default BreadDashBoardTemplate
