import { FC, useState, useEffect } from 'react'
import styles from 'styles/components/table.module.scss'
import BreadDetailRowV2 from 'components/Table/BreadDetailRowV2'
import BreadDetailInputTextRowV2 from 'components/Table/BreadDetailInputTextRowV2'
import BreadDetailAddButtonV2 from 'components/Table/BreadDetailAddButtonV2'
import BreadDetailBoxSecondaryV2 from 'components/Table/BreadDetailBoxSecondaryV2'
import {
  BreadDetailPetternInformationType,
  DataType,
} from 'reducks/business/slice'
import plusIcon from 'images/Accordion/plus-icon.png'
import minusIcon from 'images/Accordion/minus-icon.png'
import { UnitType } from 'reducks/business/slice'

type Props = {
  petternInformation: BreadDetailPetternInformationType
  editable?: boolean
  handleAddButton?: (
    dataType: DataType | undefined,
    params: (number | DataType | boolean)[] | undefined,
    unitType: UnitType.GRAM | UnitType.PERCENT,
  ) => void
  handleEditRecord?: (
    dataType: DataType | undefined,
    key:
      | 'productName'
      | 'manufacturerName'
      | 'materialId'
      | 'middleKneadPercent'
      | 'authenticKneadPercent'
      | 'supplierCostPrice'
      | 'supplierTax'
      | 'internalCapacityMemo'
      | 'yieldRate'
      | 'internalCapacityCount'
      | 'internalCapacityUnit'
      | 'numberItem'
      | 'numberItemUnit'
      | 'unitPrice1g'
      | 'cost',
    value: any,
    params: (number | DataType | boolean)[] | undefined,
    inputPetternInfos?: BreadDetailPetternInformationType[] | null,
  ) => BreadDetailPetternInformationType[]
  handleDeleteButton?: (
    dataType: DataType | undefined,
    params: (number | DataType | boolean)[] | undefined,
  ) => void
  handleIsPreview?: (
    dataType: DataType | undefined,
    params: (number | DataType | boolean)[] | undefined,
  ) => void
  dataType?: DataType
  params?: (number | DataType | boolean)[]
  parentUnitType?: UnitType
  isOpen?: boolean
  handleBoxDeleteButton?: (
    index: number | undefined,
    dataType: DataType | undefined,
  ) => void
  boxDeleteIndex?: number
  isDisplayUnitPricePerKG?: boolean
  sourceBreadId?: string | null
}

const BreadDetailBoxPrimaryV2: FC<Props> = (props) => {
  const {
    petternInformation,
    editable,
    handleAddButton,
    handleEditRecord,
    handleDeleteButton,
    handleIsPreview,
    dataType,
    params,
    parentUnitType,
    isOpen,
    handleBoxDeleteButton,
    boxDeleteIndex,
    isDisplayUnitPricePerKG,
    sourceBreadId,
  } = props
  const [open, setOpen] = useState<boolean>(false)

  const handleOpen = () => {
    // はじめに表示されるものが原材料であれば開閉アクションしない
    if (petternInformation.dataType === DataType.MATERIAL) {
      return
    }
    setOpen(!open)
  }

  useEffect(() => {
    if (isOpen) {
      handleOpen()
    }
  }, [isOpen])

  return (
    <>
      <div className={styles.bread_detail_primary_header_gray}>
        <div className={styles.bread_detail_paragraph_down_large}></div>
        <BreadDetailRowV2
          name="商品（材料）名"
          maker="メーカー"
          middlePercent="中捏"
          mainPercent="本捏"
          allPercent="計"
          allPercentNotUnit=""
          unitPrice={isDisplayUnitPricePerKG ? 'kg単価' : 'g単価'}
          gramPerPiece="1個あたり"
          gramPerPieceUnit="g"
          costPrice="原価"
          dataType={null}
          docId={null}
          isHeader={true}
          isBold={false}
          isParagraphDown={false}
          mode="primary"
          isSimulation={editable}
          handleBoxDeleteButton={handleBoxDeleteButton}
          boxDeleteIndex={boxDeleteIndex}
          boxDeleteDataType={dataType}
          isInactive={false}
          sourceBreadId={sourceBreadId}
        />
        <div className={styles.bread_detail_paragraph_down_large2}></div>
      </div>
      {
        // 最上層のレシピ or 生地パターン or フィリングパターン or 原材料
      }
      <div className={styles.bread_detail_primary_header}>
        {petternInformation.dataType !== DataType.MATERIAL ? (
          <div onClick={() => handleOpen()}>
            <img
              className={styles.bread_detail_open_close_icon}
              src={open ? minusIcon : plusIcon}
              alt={open ? '-' : '+'}
            ></img>
          </div>
        ) : (
          ''
        )}
        <div className={styles.bread_detail_paragraph_down_large3}></div>
        <WrapedBreadDetailRowV2
          petternInformation={petternInformation}
          isBold={true}
          isParagraphDown={false}
          underLine={
            petternInformation.dataType !== DataType.MATERIAL &&
            open &&
            ((petternInformation.materials &&
              petternInformation.materials.length > 0) ||
              (petternInformation.recipes &&
                petternInformation.recipes.length > 0) ||
              (petternInformation.doughs &&
                petternInformation.doughs.length > 0) ||
              (petternInformation.fillings &&
                petternInformation.fillings.length > 0) ||
              editable)
              ? 'gray'
              : undefined
          }
          editable={editable}
          isSimulation={editable}
          handleEditRecord={handleEditRecord}
          handleDeleteButton={handleDeleteButton}
          handleIsPreview={handleIsPreview}
          dataType={dataType}
          params={params}
          parentUnitType={parentUnitType}
          nameReadOnly={
            editable &&
            ((petternInformation.dataType !== DataType.MATERIAL &&
              petternInformation.materialId === null) ||
              (petternInformation.dataType === DataType.MATERIAL &&
                petternInformation.materialId !== null &&
                petternInformation.materialId !== 'manualInput'))
          }
          unDeletable={editable}
          unitPriceInvisible={
            petternInformation.dataType !== DataType.MATERIAL ? true : false
          }
          isDisplayUnitPricePerKG={isDisplayUnitPricePerKG}
          sourceBreadId={sourceBreadId}
        />
        <div className={styles.bread_detail_paragraph_down_large2}></div>
      </div>

      <div
        className={
          open &&
          ((petternInformation.recipes &&
            petternInformation.recipes.filter((recipe) => !recipe.materialId)
              .length > 0) ||
            (petternInformation.doughs &&
              petternInformation.doughs.filter((dough) => !dough.materialId)
                .length > 0) ||
            (petternInformation.fillings &&
              petternInformation.fillings.filter(
                (filling) => !filling.materialId,
              ).length > 0))
            ? styles.bread_detail_primary_header
            : ''
        }
      >
        <div
          className={
            open &&
            ((petternInformation.recipes &&
              petternInformation.recipes.filter((recipe) => !recipe.materialId)
                .length > 0) ||
              (petternInformation.doughs &&
                petternInformation.doughs.filter((dough) => !dough.materialId)
                  .length > 0) ||
              (petternInformation.fillings &&
                petternInformation.fillings.filter(
                  (filling) => !filling.materialId,
                ).length > 0))
              ? styles.bread_detail_open_close_icon
              : ''
          }
        ></div>
        <div>
          {
            // レシピ原料のうちパターンのものを表示
          }
          {open && petternInformation.recipes
            ? petternInformation.recipes
                .filter((recipe) => !recipe.materialId)
                .map((recipe, i) => (
                  <div className={styles.bread_detail_secondary_container}>
                    <BreadDetailBoxSecondaryV2
                      petternInformation={recipe}
                      level={0}
                      isTerminal={null}
                      editable={editable}
                      handleAddButton={handleAddButton}
                      handleEditRecord={handleEditRecord}
                      handleDeleteButton={handleDeleteButton}
                      handleIsPreview={handleIsPreview}
                      dataType={dataType}
                      params={
                        params ? [...params, DataType.RECIPE, false, i] : []
                      }
                      isOpen={isOpen}
                      isDisplayUnitPricePerKG={isDisplayUnitPricePerKG}
                      sourceBreadId={sourceBreadId}
                    />
                  </div>
                ))
            : ''}
          {
            // 生地原料のうちパターンのものを表示
          }
          {open && petternInformation.doughs
            ? petternInformation.doughs
                .filter((dough) => !dough.materialId)
                .map((dough, i) => (
                  <div className={styles.bread_detail_secondary_container}>
                    <BreadDetailBoxSecondaryV2
                      petternInformation={dough}
                      level={0}
                      isTerminal={null}
                      editable={editable}
                      handleAddButton={handleAddButton}
                      handleEditRecord={handleEditRecord}
                      handleDeleteButton={handleDeleteButton}
                      handleIsPreview={handleIsPreview}
                      dataType={dataType}
                      params={
                        params ? [...params, DataType.DOUGH, false, i] : []
                      }
                      isOpen={isOpen}
                      isDisplayUnitPricePerKG={isDisplayUnitPricePerKG}
                      sourceBreadId={sourceBreadId}
                    />
                  </div>
                ))
            : ''}
          {
            // フィリング原料のうちパターンのものを表示
          }
          {open && petternInformation.fillings
            ? petternInformation.fillings
                .filter((filling) => !filling.materialId)
                .map((filling, i) => (
                  <div className={styles.bread_detail_secondary_container}>
                    <BreadDetailBoxSecondaryV2
                      petternInformation={filling}
                      level={0}
                      isTerminal={null}
                      editable={editable}
                      handleAddButton={handleAddButton}
                      handleEditRecord={handleEditRecord}
                      handleDeleteButton={handleDeleteButton}
                      handleIsPreview={handleIsPreview}
                      dataType={dataType}
                      params={
                        params ? [...params, DataType.FILLING, false, i] : []
                      }
                      isOpen={isOpen}
                      isDisplayUnitPricePerKG={isDisplayUnitPricePerKG}
                      sourceBreadId={sourceBreadId}
                    />
                  </div>
                ))
            : ''}
        </div>
      </div>

      <div
        className={
          petternInformation.dataType !== DataType.MATERIAL && open
            ? styles.bread_detail_primary_header
            : ''
        }
      >
        <div
          className={
            petternInformation.dataType !== DataType.MATERIAL && open
              ? styles.bread_detail_open_close_icon
              : ''
          }
        ></div>
        <div>
          {
            // レシピ原料のうち原材料のみを表示
          }
          {open && petternInformation.recipes
            ? petternInformation.recipes
                .filter((recipe) => recipe.materialId)
                .map((recipe, i) => (
                  <div
                    className={open ? styles.bread_detail_primary_header : ''}
                  >
                    <div
                      className={styles.bread_detail_paragraph_down_large4}
                    ></div>
                    <WrapedBreadDetailRowV2
                      petternInformation={recipe}
                      isBold={true}
                      isParagraphDown={true}
                      underLine={
                        (petternInformation.recipes &&
                          petternInformation.recipes.filter(
                            (recipe) => recipe.materialId,
                          ).length -
                            1 !==
                            i) ||
                        (editable &&
                          (!petternInformation.recipes ||
                            (petternInformation.recipes &&
                              petternInformation.recipes.filter(
                                (recipe) => !recipe.materialId,
                              ).length === 0)) &&
                          (!petternInformation.doughs ||
                            (petternInformation.doughs &&
                              petternInformation.doughs.filter(
                                (dough) => !dough.materialId,
                              ).length === 0)) &&
                          (!petternInformation.fillings ||
                            (petternInformation.fillings &&
                              petternInformation.fillings.filter(
                                (filling) => !filling.materialId,
                              ).length === 0)))
                          ? 'gray'
                          : undefined
                      }
                      editable={editable}
                      isSimulation={editable}
                      handleEditRecord={handleEditRecord}
                      handleDeleteButton={handleDeleteButton}
                      handleIsPreview={handleIsPreview}
                      dataType={dataType}
                      params={
                        params ? [...params, DataType.RECIPE, true, i] : []
                      }
                      nameReadOnly={
                        recipe.materialId && recipe.materialId !== 'manualInput'
                          ? true
                          : false
                      }
                      isDisplayUnitPricePerKG={isDisplayUnitPricePerKG}
                      sourceBreadId={sourceBreadId}
                    />
                    <div
                      className={styles.bread_detail_paragraph_down_large2}
                    ></div>
                  </div>
                ))
            : ''}
          {
            // 生地原料のうち原材料のみを表示
          }
          {open && petternInformation.doughs
            ? petternInformation.doughs
                .filter((dough) => dough.materialId)
                .map((dough, i) => (
                  <div
                    className={open ? styles.bread_detail_primary_header : ''}
                  >
                    <div
                      className={styles.bread_detail_paragraph_down_large4}
                    ></div>
                    <WrapedBreadDetailRowV2
                      petternInformation={dough}
                      isBold={true}
                      isParagraphDown={true}
                      underLine={
                        (petternInformation.doughs &&
                          petternInformation.doughs.filter(
                            (dough) => dough.materialId,
                          ).length -
                            1 !==
                            i) ||
                        (editable &&
                          (!petternInformation.recipes ||
                            (petternInformation.recipes &&
                              petternInformation.recipes.filter(
                                (recipe) => !recipe.materialId,
                              ).length === 0)) &&
                          (!petternInformation.doughs ||
                            (petternInformation.doughs &&
                              petternInformation.doughs.filter(
                                (dough) => !dough.materialId,
                              ).length === 0)) &&
                          (!petternInformation.fillings ||
                            (petternInformation.fillings &&
                              petternInformation.fillings.filter(
                                (filling) => !filling.materialId,
                              ).length === 0)))
                          ? 'gray'
                          : undefined
                      }
                      editable={editable}
                      isSimulation={editable}
                      handleEditRecord={handleEditRecord}
                      handleDeleteButton={handleDeleteButton}
                      handleIsPreview={handleIsPreview}
                      dataType={dataType}
                      params={
                        params ? [...params, DataType.DOUGH, true, i] : []
                      }
                      nameReadOnly={
                        dough.materialId && dough.materialId !== 'manualInput'
                          ? true
                          : false
                      }
                      isDisplayUnitPricePerKG={isDisplayUnitPricePerKG}
                      sourceBreadId={sourceBreadId}
                    />
                    <div
                      className={styles.bread_detail_paragraph_down_large2}
                    ></div>
                  </div>
                ))
            : ''}
          {
            // フィリング原料のうち原材料のみを表示
          }
          {open && petternInformation.fillings
            ? petternInformation.fillings
                .filter((filling) => filling.materialId)
                .map((filling, i) => (
                  <div
                    className={open ? styles.bread_detail_primary_header : ''}
                  >
                    <div
                      className={styles.bread_detail_paragraph_down_large4}
                    ></div>
                    <WrapedBreadDetailRowV2
                      petternInformation={filling}
                      isBold={true}
                      isParagraphDown={true}
                      underLine={
                        (petternInformation.fillings &&
                          petternInformation.fillings.filter(
                            (filling) => filling.materialId,
                          ).length -
                            1 !==
                            i) ||
                        (editable &&
                          (!petternInformation.recipes ||
                            (petternInformation.recipes &&
                              petternInformation.recipes.filter(
                                (recipe) => !recipe.materialId,
                              ).length === 0)) &&
                          (!petternInformation.doughs ||
                            (petternInformation.doughs &&
                              petternInformation.doughs.filter(
                                (dough) => !dough.materialId,
                              ).length === 0)) &&
                          (!petternInformation.fillings ||
                            (petternInformation.fillings &&
                              petternInformation.fillings.filter(
                                (filling) => !filling.materialId,
                              ).length === 0)))
                          ? 'gray'
                          : undefined
                      }
                      editable={editable}
                      isSimulation={editable}
                      handleEditRecord={handleEditRecord}
                      handleDeleteButton={handleDeleteButton}
                      handleIsPreview={handleIsPreview}
                      dataType={dataType}
                      params={
                        params ? [...params, DataType.FILLING, true, i] : []
                      }
                      nameReadOnly={
                        filling.materialId &&
                        filling.materialId !== 'manualInput'
                          ? true
                          : false
                      }
                      isDisplayUnitPricePerKG={isDisplayUnitPricePerKG}
                      sourceBreadId={sourceBreadId}
                    />
                    <div
                      className={styles.bread_detail_paragraph_down_large2}
                    ></div>
                  </div>
                ))
            : ''}
          {
            // 直接紐づく原材料を表示
          }
          {open && petternInformation.materials
            ? petternInformation.materials.map((material, i) => (
                <div className={styles.bread_detail_primary_header}>
                  <div
                    className={styles.bread_detail_paragraph_down_large4}
                  ></div>
                  <WrapedBreadDetailRowV2
                    petternInformation={material}
                    isBold={true}
                    isParagraphDown={true}
                    underLine={
                      (petternInformation.materials &&
                        petternInformation.materials.length - 1 !== i) ||
                      editable
                        ? 'gray'
                        : undefined
                    }
                    editable={editable}
                    isSimulation={editable}
                    handleEditRecord={handleEditRecord}
                    handleDeleteButton={handleDeleteButton}
                    handleIsPreview={handleIsPreview}
                    dataType={dataType}
                    params={
                      params ? [...params, DataType.MATERIAL, true, i] : []
                    }
                    nameReadOnly={
                      material.materialId &&
                      material.materialId !== 'manualInput'
                        ? true
                        : false
                    }
                    isDisplayUnitPricePerKG={isDisplayUnitPricePerKG}
                    sourceBreadId={sourceBreadId}
                  />
                  <div
                    className={styles.bread_detail_paragraph_down_large2}
                  ></div>
                </div>
              ))
            : ''}
        </div>
      </div>
      {petternInformation.dataType !== DataType.MATERIAL && open && editable ? (
        <div className={styles.bread_detail_add_button}>
          <BreadDetailAddButtonV2
            handleAddButton={handleAddButton}
            dataType={dataType}
            params={params}
            unitType={
              petternInformation.recipes &&
              petternInformation.recipes.length > 0
                ? petternInformation.recipes[0].unitType
                : petternInformation.doughs &&
                  petternInformation.doughs.length > 0
                ? petternInformation.doughs[0].unitType
                : petternInformation.fillings &&
                  petternInformation.fillings.length > 0
                ? petternInformation.fillings[0].unitType
                : petternInformation.materials &&
                  petternInformation.materials.length > 0
                ? petternInformation.materials[0].unitType
                : UnitType.GRAM
            }
          />
        </div>
      ) : (
        ''
      )}
    </>
  )
}

type WrapedProps = {
  petternInformation: BreadDetailPetternInformationType
  isBold: boolean
  isParagraphDown: boolean
  underLine?: 'gray' | 'orange' | undefined
  editable?: boolean
  isSimulation?: boolean
  handleEditRecord?: (
    dataType: DataType | undefined,
    key:
      | 'productName'
      | 'manufacturerName'
      | 'materialId'
      | 'middleKneadPercent'
      | 'authenticKneadPercent'
      | 'supplierCostPrice'
      | 'supplierTax'
      | 'internalCapacityMemo'
      | 'yieldRate'
      | 'internalCapacityCount'
      | 'internalCapacityUnit'
      | 'numberItem'
      | 'numberItemUnit'
      | 'unitPrice1g'
      | 'cost',
    value: any,
    params: (number | DataType | boolean)[] | undefined,
    inputPetternInfos?: BreadDetailPetternInformationType[] | null,
  ) => BreadDetailPetternInformationType[]
  handleDeleteButton?: (
    dataType: DataType | undefined,
    params: (number | DataType | boolean)[] | undefined,
  ) => void
  handleIsPreview?: (
    dataType: DataType | undefined,
    params: (number | DataType | boolean)[] | undefined,
  ) => void
  dataType?: DataType
  params?: (number | DataType | boolean)[]
  parentUnitType?: UnitType
  handleBoxDeleteButton?: () => void
  nameReadOnly?: boolean
  unDeletable?: boolean
  unitPriceInvisible?: boolean
  isDisplayUnitPricePerKG?: boolean
  sourceBreadId?: string | null
}

const WrapedBreadDetailRowV2: FC<WrapedProps> = (props) => {
  const {
    petternInformation,
    isBold,
    isParagraphDown,
    underLine,
    editable,
    isSimulation,
    handleEditRecord,
    handleDeleteButton,
    handleIsPreview,
    dataType,
    params,
    parentUnitType,
    handleBoxDeleteButton,
    nameReadOnly,
    unDeletable,
    unitPriceInvisible,
    isDisplayUnitPricePerKG,
    sourceBreadId,
  } = props

  const recursiveUnitPriceSum = (
    petternInformation: BreadDetailPetternInformationType,
  ) => {
    let priceSum = 0
    if (petternInformation.unitPrice1g) {
      priceSum += petternInformation.unitPrice1g
    }
    if (petternInformation.materials) {
      priceSum += petternInformation.materials.reduce((sum, material) => {
        return material.unitPrice1g ? sum + material.unitPrice1g : sum
      }, 0)
    }
    if (petternInformation.recipes) {
      petternInformation.recipes.forEach((recipe) => {
        priceSum += recursiveUnitPriceSum(recipe)
      })
    }
    if (petternInformation.doughs) {
      petternInformation.doughs.forEach((dough) => {
        priceSum += recursiveUnitPriceSum(dough)
      })
    }
    if (petternInformation.fillings) {
      petternInformation.fillings.forEach((filling) => {
        priceSum += recursiveUnitPriceSum(filling)
      })
    }
    return priceSum
  }

  const recursiveCostPriceSum = (
    petternInformation: BreadDetailPetternInformationType,
  ) => {
    let priceSum = 0
    if (petternInformation.cost) {
      priceSum += petternInformation.cost
    }
    if (petternInformation.materials) {
      priceSum += petternInformation.materials.reduce((sum, material) => {
        return material.cost ? sum + material.cost : sum
      }, 0)
    }
    if (petternInformation.recipes) {
      petternInformation.recipes.forEach((recipe) => {
        priceSum += recursiveCostPriceSum(recipe)
      })
    }
    if (petternInformation.doughs) {
      petternInformation.doughs.forEach((dough) => {
        priceSum += recursiveCostPriceSum(dough)
      })
    }
    if (petternInformation.fillings) {
      petternInformation.fillings.forEach((filling) => {
        priceSum += recursiveCostPriceSum(filling)
      })
    }
    return priceSum
  }

  const determineUnit = () => {
    return parentUnitType ? parentUnitType : petternInformation.unitType
  }

  const unitAdjustment = isDisplayUnitPricePerKG ? 1000 : 1

  return (
    <>
      {editable ? (
        <BreadDetailInputTextRowV2
          name={petternInformation.productName}
          maker={
            petternInformation.manufacturerName
              ? petternInformation.manufacturerName
              : ''
          }
          middlePercent={
            unitPriceInvisible && petternInformation.middleKneadPercent === 0
              ? ''
              : String(petternInformation.middleKneadPercent)
          }
          middlePercentUnit={determineUnit()}
          mainPercent={
            unitPriceInvisible && petternInformation.authenticKneadPercent === 0
              ? ''
              : String(petternInformation.authenticKneadPercent)
          }
          mainPercentUnit={determineUnit()}
          allPercent={String(
            Number(petternInformation.middleKneadPercent) +
              Number(petternInformation.authenticKneadPercent),
          )}
          allPercentUnit={determineUnit()}
          unitPrice={
            petternInformation.unitPrice1g
              ? String(
                  (
                    Math.round(petternInformation.unitPrice1g * 100) / 100
                  ).toFixed(2),
                )
              : String(
                  (
                    Math.round(
                      recursiveUnitPriceSum(petternInformation) * 100,
                    ) / 100
                  ).toFixed(2),
                )
          }
          unitPriceUnit={'円 / g'}
          costPrice={
            petternInformation.cost
              ? String(
                  (Math.floor(petternInformation.cost * 10) / 10).toFixed(1),
                )
              : String(
                  (
                    Math.floor(recursiveCostPriceSum(petternInformation) * 10) /
                    10
                  ).toFixed(1),
                )
          }
          costPriceUnit={'円'}
          isHeader={false}
          isBold={isBold}
          isParagraphDown={isParagraphDown}
          underLine={underLine}
          mode="primary"
          handleEditRecord={handleEditRecord}
          handleDeleteButton={handleDeleteButton}
          handleIsPreview={handleIsPreview}
          dataType={dataType}
          params={params}
          nameReadOnly={nameReadOnly}
          unDeletable={unDeletable}
          unitPriceInvisible={unitPriceInvisible}
        />
      ) : (
        <BreadDetailRowV2
          name={petternInformation.productName}
          maker={
            petternInformation.manufacturerName
              ? petternInformation.manufacturerName
              : ''
          }
          middlePercent={
            String(petternInformation.middleKneadPercent) + determineUnit()
          }
          mainPercent={
            String(petternInformation.authenticKneadPercent) + determineUnit()
          }
          allPercent={
            String(
              Number(petternInformation.middleKneadPercent) +
                Number(petternInformation.authenticKneadPercent),
            ) + determineUnit()
          }
          unitPrice={
            petternInformation.materialId === 'manualInput'
              ? '材料未登録'
              : petternInformation.materialId &&
                !petternInformation.supplierCostPrice &&
                petternInformation.supplierCostPrice !== 0
              ? '原価未登録'
              : petternInformation.unitPrice1g
              ? String(
                  (petternInformation.unitPrice1g * unitAdjustment).toFixed(2),
                ) + (unitAdjustment === 1000 ? '円 / kg' : '円 / g')
              : String(
                  (
                    recursiveUnitPriceSum(petternInformation) * unitAdjustment
                  ).toFixed(2),
                ) + (unitAdjustment === 1000 ? '円 / kg' : '円 / g')
          }
          allPercentNotUnit={String(
            Number(petternInformation.middleKneadPercent) +
              Number(petternInformation.authenticKneadPercent),
          )}
          gramPerPiece={
            petternInformation.gramPerPiece
              ? String(petternInformation.gramPerPiece)
              : null
          }
          gramPerPieceUnit={petternInformation.gramPerPieceUnit}
          costPrice={
            petternInformation.materialId === 'manualInput'
              ? '材料未登録'
              : petternInformation.materialId &&
                !petternInformation.supplierCostPrice &&
                petternInformation.supplierCostPrice !== 0
              ? '原価未登録'
              : petternInformation.cost
              ? String(petternInformation.cost.toFixed(1)) + '円'
              : String(recursiveCostPriceSum(petternInformation).toFixed(1)) +
                '円'
          }
          dataType={petternInformation.dataType}
          docId={
            petternInformation.materialId ? petternInformation.materialId : null
          }
          isHeader={false}
          isBold={isBold}
          isParagraphDown={isParagraphDown}
          underLine={underLine}
          mode="primary"
          isSimulation={isSimulation}
          handleBoxDeleteButton={handleBoxDeleteButton}
          unitPriceInvisible={unitPriceInvisible}
          isInactive={!!(petternInformation.materialId === 'manualInput')}
          sourceBreadId={sourceBreadId}
        />
      )}
    </>
  )
}

export default BreadDetailBoxPrimaryV2
