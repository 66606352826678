import { FC, useState, useEffect, useCallback, MouseEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import styles from 'styles/components/template.module.scss'
import PageHedding from 'components/PageHedding/PageHedding'
import RadioButtonFilterWithLightLabel from 'components/Form/WithLightLabel/RadioButtonFilter'
import InputTextWithLightLabel from 'components/Form/WithLightLabel/InputText'
import SelectCheckBoxWithLightLabel from 'components/Form/WithLightLabel/SelectCheckBox'
import SubmitButton from 'components/Button/SubmitButton'
import Table, { TableBody } from 'components/Table/Table'
import {
  getBreads,
  BreadType,
  //  updateSellingPrice,
  //  updateIdealCostRate,
  BackingStickerStatus,
  getBreadByRecipeId,
  clearBreads,
  BreadStatus,
} from 'reducks/bread/slice'
import { RootState } from 'reducks/reducers'
import { TAX } from 'Constants'
import { setIsLoading } from 'reducks/loading/slice'
import alertIcon from 'images/alert-icon.png'
import { getMaterialById, MaterialType } from 'reducks/material/slice'
import ExcelJS from 'exceljs'

type CsvOutputInfo = {
  breadId: string
  breadName: string
  sellingPrice: string
  costPrice: string
  costRate: string
  notCostCalculatedReasonText?: string
}

const BreadCostTemplate: FC = () => {
  const dispatch = useDispatch<any>()
  const navigate = useNavigate()
  const location = useLocation()
  const path = location.pathname
  const [breadList, setBreadList] = useState<TableBody[][]>([])
  const [breadSaling, setBreadSaling] = useState<boolean>(false)
  const [breadSalesSuspension, setBreadSalesSuspension] =
    useState<boolean>(false)
  const [costInputComplete, setCostInputComplete] = useState<boolean>(false)
  const [costInputNotComplete, setCostInputNotComplete] =
    useState<boolean>(false)

  const [searchWord, setSearchWord] = useState<string>('')
  const [categoryIsChecked0, setCategoryIsChecked0] = useState<boolean>(false)
  const [categoryIsChecked1, setCategoryIsChecked1] = useState<boolean>(false)
  const [categoryIsChecked2, setCategoryIsChecked2] = useState<boolean>(false)
  const [categoryIsChecked3, setCategoryIsChecked3] = useState<boolean>(false)
  const [categoryIsChecked4, setCategoryIsChecked4] = useState<boolean>(false)
  const [categoryIsChecked5, setCategoryIsChecked5] = useState<boolean>(false)
  const [categoryIsChecked6, setCategoryIsChecked6] = useState<boolean>(false)
  const [categoryIsChecked7, setCategoryIsChecked7] = useState<boolean>(false)
  const [categoryIsChecked8, setCategoryIsChecked8] = useState<boolean>(false)
  const [categoryIsChecked9, setCategoryIsChecked9] = useState<boolean>(false)
  const [displaySelectBoxValue, setDisplaySelectBoxValue] = useState<string>('')
  const [displaySelectBoxPlaceholder, setDisplaySelectBoxPlaceholder] =
    useState<string>('1')

  const [formData, setFormData] = useState<Map<string, string>>(
    new Map<string, string>(),
  )
  const [notCostCalculatedReasonTextMap, setNotCostCalculatedReasonTextMap] =
    useState<Map<string, string>>(new Map<string, string>())
  const [currentBreads, setCurrentBreads] = useState<BreadType[]>([])
  const [csvList, setCsvList] = useState<any>([])

  const { categories } = useSelector((state: RootState) => state.categorySlice)

  const handleSearchWord = (searchWord: string) => {
    setSearchWord(searchWord)
    breadFilter({
      inputSearchWord: searchWord,
      inputCategoryIsChecked0: categoryIsChecked0,
      inputCategoryIsChecked1: categoryIsChecked1,
      inputCategoryIsChecked2: categoryIsChecked2,
      inputCategoryIsChecked3: categoryIsChecked3,
      inputCategoryIsChecked4: categoryIsChecked4,
      inputCategoryIsChecked5: categoryIsChecked5,
      inputCategoryIsChecked6: categoryIsChecked6,
      inputCategoryIsChecked7: categoryIsChecked7,
      inputCategoryIsChecked8: categoryIsChecked8,
      inputCategoryIsChecked9: categoryIsChecked9,
      inputBreadSaling: breadSaling,
      inputBreadSalesSuspension: breadSalesSuspension,
      inputCostInputComplete: costInputComplete,
      inputCostInputNotComplete: costInputNotComplete,
    })
  }

  const handleBreadCategory = (index: number) => {
    let isChecked0 = categoryIsChecked0
    let isChecked1 = categoryIsChecked1
    let isChecked2 = categoryIsChecked2
    let isChecked3 = categoryIsChecked3
    let isChecked4 = categoryIsChecked4
    let isChecked5 = categoryIsChecked5
    let isChecked6 = categoryIsChecked6
    let isChecked7 = categoryIsChecked7
    let isChecked8 = categoryIsChecked8
    let isChecked9 = categoryIsChecked9
    if (index === 0) {
      setCategoryIsChecked0(!categoryIsChecked0 ? true : false)
      isChecked0 = !categoryIsChecked0
    }
    if (index === 1) {
      setCategoryIsChecked1(!categoryIsChecked1 ? true : false)
      isChecked1 = !categoryIsChecked1
    }
    if (index === 2) {
      setCategoryIsChecked2(!categoryIsChecked2 ? true : false)
      isChecked2 = !categoryIsChecked2
    }
    if (index === 3) {
      setCategoryIsChecked3(!categoryIsChecked3 ? true : false)
      isChecked3 = !categoryIsChecked3
    }
    if (index === 4) {
      setCategoryIsChecked4(!categoryIsChecked4 ? true : false)
      isChecked4 = !categoryIsChecked4
    }
    if (index === 5) {
      setCategoryIsChecked5(!categoryIsChecked5 ? true : false)
      isChecked5 = !categoryIsChecked5
    }
    if (index === 6) {
      setCategoryIsChecked6(!categoryIsChecked6 ? true : false)
      isChecked6 = !categoryIsChecked6
    }
    if (index === 7) {
      setCategoryIsChecked7(!categoryIsChecked7 ? true : false)
      isChecked7 = !categoryIsChecked7
    }
    if (index === 8) {
      setCategoryIsChecked8(!categoryIsChecked8 ? true : false)
      isChecked8 = !categoryIsChecked8
    }
    if (index === 9) {
      setCategoryIsChecked9(!categoryIsChecked9 ? true : false)
      isChecked9 = !categoryIsChecked9
    }

    let newDisplaySelectBoxValue = ''
    if (isChecked0) {
      newDisplaySelectBoxValue =
        newDisplaySelectBoxValue + categories[0].name + ' / '
    }
    if (isChecked1) {
      newDisplaySelectBoxValue =
        newDisplaySelectBoxValue + categories[1].name + ' / '
    }
    if (isChecked2) {
      newDisplaySelectBoxValue =
        newDisplaySelectBoxValue + categories[2].name + ' / '
    }
    if (isChecked3) {
      newDisplaySelectBoxValue =
        newDisplaySelectBoxValue + categories[3].name + ' / '
    }
    if (isChecked4) {
      newDisplaySelectBoxValue =
        newDisplaySelectBoxValue + categories[4].name + ' / '
    }
    if (isChecked5) {
      newDisplaySelectBoxValue =
        newDisplaySelectBoxValue + categories[5].name + ' / '
    }
    if (isChecked6) {
      newDisplaySelectBoxValue =
        newDisplaySelectBoxValue + categories[6].name + ' / '
    }
    if (isChecked7) {
      newDisplaySelectBoxValue =
        newDisplaySelectBoxValue + categories[7].name + ' / '
    }
    if (isChecked8) {
      newDisplaySelectBoxValue =
        newDisplaySelectBoxValue + categories[8].name + ' / '
    }
    if (isChecked9) {
      newDisplaySelectBoxValue =
        newDisplaySelectBoxValue + categories[9].name + ' / '
    }
    // 末尾の2文字を削除
    newDisplaySelectBoxValue = newDisplaySelectBoxValue.slice(0, -2)
    setDisplaySelectBoxValue(newDisplaySelectBoxValue)

    if (newDisplaySelectBoxValue.length === 0) {
      setDisplaySelectBoxPlaceholder('1')
    } else {
      setDisplaySelectBoxPlaceholder('0')
    }

    breadFilter({
      inputSearchWord: searchWord,
      inputCategoryIsChecked0: isChecked0,
      inputCategoryIsChecked1: isChecked1,
      inputCategoryIsChecked2: isChecked2,
      inputCategoryIsChecked3: isChecked3,
      inputCategoryIsChecked4: isChecked4,
      inputCategoryIsChecked5: isChecked5,
      inputCategoryIsChecked6: isChecked6,
      inputCategoryIsChecked7: isChecked7,
      inputCategoryIsChecked8: isChecked8,
      inputCategoryIsChecked9: isChecked9,
      inputBreadSaling: breadSaling,
      inputBreadSalesSuspension: breadSalesSuspension,
      inputCostInputComplete: costInputComplete,
      inputCostInputNotComplete: costInputNotComplete,
    })
  }

  const handleBreadSalesAll = () => {
    setBreadSaling(false)
    setBreadSalesSuspension(false)
    breadFilter({
      inputSearchWord: searchWord,
      inputCategoryIsChecked0: categoryIsChecked0,
      inputCategoryIsChecked1: categoryIsChecked1,
      inputCategoryIsChecked2: categoryIsChecked2,
      inputCategoryIsChecked3: categoryIsChecked3,
      inputCategoryIsChecked4: categoryIsChecked4,
      inputCategoryIsChecked5: categoryIsChecked5,
      inputCategoryIsChecked6: categoryIsChecked6,
      inputCategoryIsChecked7: categoryIsChecked7,
      inputCategoryIsChecked8: categoryIsChecked8,
      inputCategoryIsChecked9: categoryIsChecked9,
      inputBreadSaling: false,
      inputBreadSalesSuspension: false,
      inputCostInputComplete: costInputComplete,
      inputCostInputNotComplete: costInputNotComplete,
    })
  }
  const handleBreadSaling = () => {
    setBreadSaling(true)
    setBreadSalesSuspension(false)
    breadFilter({
      inputSearchWord: searchWord,
      inputCategoryIsChecked0: categoryIsChecked0,
      inputCategoryIsChecked1: categoryIsChecked1,
      inputCategoryIsChecked2: categoryIsChecked2,
      inputCategoryIsChecked3: categoryIsChecked3,
      inputCategoryIsChecked4: categoryIsChecked4,
      inputCategoryIsChecked5: categoryIsChecked5,
      inputCategoryIsChecked6: categoryIsChecked6,
      inputCategoryIsChecked7: categoryIsChecked7,
      inputCategoryIsChecked8: categoryIsChecked8,
      inputCategoryIsChecked9: categoryIsChecked9,
      inputBreadSaling: true,
      inputBreadSalesSuspension: false,
      inputCostInputComplete: costInputComplete,
      inputCostInputNotComplete: costInputNotComplete,
    })
  }
  const handleBreadSalesSuspension = () => {
    setBreadSaling(false)
    setBreadSalesSuspension(true)
    breadFilter({
      inputSearchWord: searchWord,
      inputCategoryIsChecked0: categoryIsChecked0,
      inputCategoryIsChecked1: categoryIsChecked1,
      inputCategoryIsChecked2: categoryIsChecked2,
      inputCategoryIsChecked3: categoryIsChecked3,
      inputCategoryIsChecked4: categoryIsChecked4,
      inputCategoryIsChecked5: categoryIsChecked5,
      inputCategoryIsChecked6: categoryIsChecked6,
      inputCategoryIsChecked7: categoryIsChecked7,
      inputCategoryIsChecked8: categoryIsChecked8,
      inputCategoryIsChecked9: categoryIsChecked9,
      inputBreadSaling: false,
      inputBreadSalesSuspension: true,
      inputCostInputComplete: costInputComplete,
      inputCostInputNotComplete: costInputNotComplete,
    })
  }
  const handleCostInputCompleteAll = () => {
    setCostInputComplete(false)
    setCostInputNotComplete(false)
    breadFilter({
      inputSearchWord: searchWord,
      inputCategoryIsChecked0: categoryIsChecked0,
      inputCategoryIsChecked1: categoryIsChecked1,
      inputCategoryIsChecked2: categoryIsChecked2,
      inputCategoryIsChecked3: categoryIsChecked3,
      inputCategoryIsChecked4: categoryIsChecked4,
      inputCategoryIsChecked5: categoryIsChecked5,
      inputCategoryIsChecked6: categoryIsChecked6,
      inputCategoryIsChecked7: categoryIsChecked7,
      inputCategoryIsChecked8: categoryIsChecked8,
      inputCategoryIsChecked9: categoryIsChecked9,
      inputBreadSaling: breadSaling,
      inputBreadSalesSuspension: breadSalesSuspension,
      inputCostInputComplete: false,
      inputCostInputNotComplete: false,
    })
  }
  const handleCostInputComplete = () => {
    setCostInputComplete(true)
    setCostInputNotComplete(false)
    breadFilter({
      inputSearchWord: searchWord,
      inputCategoryIsChecked0: categoryIsChecked0,
      inputCategoryIsChecked1: categoryIsChecked1,
      inputCategoryIsChecked2: categoryIsChecked2,
      inputCategoryIsChecked3: categoryIsChecked3,
      inputCategoryIsChecked4: categoryIsChecked4,
      inputCategoryIsChecked5: categoryIsChecked5,
      inputCategoryIsChecked6: categoryIsChecked6,
      inputCategoryIsChecked7: categoryIsChecked7,
      inputCategoryIsChecked8: categoryIsChecked8,
      inputCategoryIsChecked9: categoryIsChecked9,
      inputBreadSaling: breadSaling,
      inputBreadSalesSuspension: breadSalesSuspension,
      inputCostInputComplete: true,
      inputCostInputNotComplete: false,
    })
  }
  const handleCostInputNotComplete = () => {
    setCostInputComplete(false)
    setCostInputNotComplete(true)
    breadFilter({
      inputSearchWord: searchWord,
      inputCategoryIsChecked0: categoryIsChecked0,
      inputCategoryIsChecked1: categoryIsChecked1,
      inputCategoryIsChecked2: categoryIsChecked2,
      inputCategoryIsChecked3: categoryIsChecked3,
      inputCategoryIsChecked4: categoryIsChecked4,
      inputCategoryIsChecked5: categoryIsChecked5,
      inputCategoryIsChecked6: categoryIsChecked6,
      inputCategoryIsChecked7: categoryIsChecked7,
      inputCategoryIsChecked8: categoryIsChecked8,
      inputCategoryIsChecked9: categoryIsChecked9,
      inputBreadSaling: breadSaling,
      inputBreadSalesSuspension: breadSalesSuspension,
      inputCostInputComplete: false,
      inputCostInputNotComplete: true,
    })
  }
  const breadFilter = (payload: {
    inputSearchWord: string
    inputCategoryIsChecked0: boolean
    inputCategoryIsChecked1: boolean
    inputCategoryIsChecked2: boolean
    inputCategoryIsChecked3: boolean
    inputCategoryIsChecked4: boolean
    inputCategoryIsChecked5: boolean
    inputCategoryIsChecked6: boolean
    inputCategoryIsChecked7: boolean
    inputCategoryIsChecked8: boolean
    inputCategoryIsChecked9: boolean
    inputBreadSaling: boolean
    inputBreadSalesSuspension: boolean
    inputCostInputComplete: boolean
    inputCostInputNotComplete: boolean
  }) => {
    let newBreads = [...breads]

    let newSearchWord = payload.inputSearchWord
    if (newSearchWord) {
      newBreads = newBreads.filter(
        (bread) =>
          bread.name
            .replace(/\s+/g, '')
            .toLowerCase()
            .indexOf(newSearchWord.replace(/\s+/g, '').toLowerCase()) > -1,
      )
    }
    let newCategoryIsChecked0 = payload.inputCategoryIsChecked0
    let newCategoryIsChecked1 = payload.inputCategoryIsChecked1
    let newCategoryIsChecked2 = payload.inputCategoryIsChecked2
    let newCategoryIsChecked3 = payload.inputCategoryIsChecked3
    let newCategoryIsChecked4 = payload.inputCategoryIsChecked4
    let newCategoryIsChecked5 = payload.inputCategoryIsChecked5
    let newCategoryIsChecked6 = payload.inputCategoryIsChecked6
    let newCategoryIsChecked7 = payload.inputCategoryIsChecked7
    let newCategoryIsChecked8 = payload.inputCategoryIsChecked8
    let newCategoryIsChecked9 = payload.inputCategoryIsChecked9
    if (
      newCategoryIsChecked0 ||
      newCategoryIsChecked1 ||
      newCategoryIsChecked2 ||
      newCategoryIsChecked3 ||
      newCategoryIsChecked4 ||
      newCategoryIsChecked5 ||
      newCategoryIsChecked6 ||
      newCategoryIsChecked7 ||
      newCategoryIsChecked8 ||
      newCategoryIsChecked9
    ) {
      newBreads = newBreads.filter((bread) => {
        if (bread.category) {
          if (
            newCategoryIsChecked0 &&
            bread.category.id === categories[0].category_ref.id
          ) {
            return true
          }
          if (
            newCategoryIsChecked1 &&
            bread.category.id === categories[1].category_ref.id
          ) {
            return true
          }
          if (
            newCategoryIsChecked2 &&
            bread.category.id === categories[2].category_ref.id
          ) {
            return true
          }
          if (
            newCategoryIsChecked3 &&
            bread.category.id === categories[3].category_ref.id
          ) {
            return true
          }
          if (
            newCategoryIsChecked4 &&
            bread.category.id === categories[4].category_ref.id
          ) {
            return true
          }
          if (
            newCategoryIsChecked5 &&
            bread.category.id === categories[5].category_ref.id
          ) {
            return true
          }
          if (
            newCategoryIsChecked6 &&
            bread.category.id === categories[6].category_ref.id
          ) {
            return true
          }
          if (
            newCategoryIsChecked7 &&
            bread.category.id === categories[7].category_ref.id
          ) {
            return true
          }
          if (
            newCategoryIsChecked8 &&
            bread.category.id === categories[8].category_ref.id
          ) {
            return true
          }
          if (
            newCategoryIsChecked9 &&
            bread.category.id === categories[9].category_ref.id
          ) {
            return true
          }
        }
        return false
      })
    }
    let newBreadSaling = payload.inputBreadSaling
    let newBreadSalesSuspension = payload.inputBreadSalesSuspension
    if (newBreadSaling || newBreadSalesSuspension) {
      newBreads = newBreads.filter((bread) => {
        if (newBreadSaling && bread.is_salling === newBreadSaling) {
          return true
        }
        if (
          newBreadSalesSuspension &&
          (bread.is_salling === !newBreadSalesSuspension || !bread.is_salling)
        ) {
          return true
        }
        return false
      })
    }
    let newCostInputComplete = payload.inputCostInputComplete
    let newCostInputNotComplete = payload.inputCostInputNotComplete
    if (newCostInputComplete || newCostInputNotComplete) {
      newBreads = newBreads.filter((bread) => {
        if (
          newCostInputComplete &&
          bread.cost_price &&
          Object.keys(bread.not_cost_calculated_reasons).length === 0 &&
          (!bread.not_cost_calculated_recipe_reasons ||
            bread.not_cost_calculated_recipe_reasons.length === 0) &&
          bread.daseruno_recipe_status === BreadStatus.PUBLIC &&
          bread.backing_sticker_status === BackingStickerStatus.OK
        ) {
          return true
        }
        if (
          newCostInputNotComplete &&
          !(
            bread.cost_price &&
            Object.keys(bread.not_cost_calculated_reasons).length === 0 &&
            (!bread.not_cost_calculated_recipe_reasons ||
              bread.not_cost_calculated_recipe_reasons.length === 0) &&
            bread.daseruno_recipe_status === BreadStatus.PUBLIC &&
            bread.backing_sticker_status === BackingStickerStatus.OK
          )
        ) {
          return true
        }
        return false
      })
    }
    setCurrentBreads(newBreads)
    createBreadList(newBreads, notCostCalculatedReasonTextMap)
  }

  const handleFormUnit = (
    breadId: string | undefined,
    type: string | undefined,
    value: string,
  ) => {
    if (!breadId || !type) {
      return
    }
    const newFormData = new Map<string, string>(formData)
    const targetBread = breads.filter((bread) => bread.id === breadId)
    const cost_price = targetBread[0].cost_price
    if (cost_price) {
      if (type === 'updated_selling_price') {
        // 変更後原価率を設定
        const selling_price = Number(value)
        // 小数点第二位を切り上げ
        newFormData.set(
          breadId + '*updated_cost_rate',
          String(
            Math.ceil(
              ((selling_price - cost_price) / selling_price) * 100 * 100,
            ) / 100,
          ),
        )
      }
      if (type === 'updated_cost_rate') {
        // 変更後売価を設定
        const cost_rate = Number(value)
        newFormData.set(
          breadId + '*updated_selling_price',
          String(Math.ceil((100 * cost_price) / (100 - cost_rate))),
        )
      }
      if (type === 'simulate_selling_price') {
        // シミュレーション原価率をクリア
        newFormData.set(breadId + '*simulate_cost_rate', '')
      }
      if (type === 'simulate_cost_rate') {
        // シミュレーション売価をクリア
        newFormData.set(breadId + '*simulate_selling_price', '')
      }
    }
    newFormData.set(breadId + '*' + type, value)
    setFormData(newFormData)
  }

  const { breads } = useSelector((state: RootState) => state.breadSlice)
  const dispatchGetBreads = useCallback(async () => {
    if (!staff) {
      navigate('/initload?path=' + path)
      window.scrollTo(0, 0)
      return
    }
    // 使用できないプラン or スーパー管理者じゃなければ使用できない
    if (
      (staff?.isAdmin === true && adminShopId) ||
      (staff?.isAdmin === false && staff.isStandardPlan === true)
    ) {
      //
    } else {
      navigate('/initload')
      window.scrollTo(0, 0)
    }
    dispatch(setIsLoading({ isLoading: true }))
    await dispatch(clearBreads())
    const breadsPayload = await dispatch(getBreads())
    const breads: BreadType[] = breadsPayload.payload
    let notCostCalculatedReasonTextMap: Map<string, string> = new Map<
      string,
      string
    >()
    await Promise.all(
      breads.map(async (bread) => {
        let notCostCalculatedReasonArray = []
        let notAmountDoughBeforeBakingFlg = false
        if (bread.not_cost_calculated_reasons) {
          await Promise.all(
            Object.keys(bread.not_cost_calculated_reasons).map(
              async (materialId) => {
                const materialPayload = await dispatch(
                  getMaterialById({ id: materialId }),
                )
                const material: MaterialType | null = materialPayload.payload
                if (material) {
                  if (
                    bread.not_cost_calculated_reasons[materialId]
                      .notAmountDoughBeforeBaking
                  ) {
                    // 呼び出しレシピの焼成前重量がないのか、元のレシピの焼成前重量がないのか判定
                    const notAmountDoughBeforeBakingRecipeId =
                      bread.not_cost_calculated_reasons[materialId]
                        .notAmountDoughBeforeBakingRecipeId
                    if (notAmountDoughBeforeBakingRecipeId) {
                      const breadCallRecipePayload = await dispatch(
                        getBreadByRecipeId({
                          id: notAmountDoughBeforeBakingRecipeId,
                        }),
                      )
                      const breadCallRecipe: BreadType =
                        breadCallRecipePayload.payload
                      if (breadCallRecipe) {
                        // 呼び出しレシピの方にない
                        notCostCalculatedReasonArray.push(
                          '「' +
                            breadCallRecipe.name +
                            '」の「商品１個あたりの焼成後の重量」が入力されていません。',
                        )
                      } else {
                        // 元のレシピにないと見なす
                        notAmountDoughBeforeBakingFlg = true
                      }
                    } else {
                      // 元のレシピにない
                      notAmountDoughBeforeBakingFlg = true
                    }
                  }
                  if (
                    bread.not_cost_calculated_reasons[materialId]
                      .notInputInternalCapacity
                  ) {
                    notCostCalculatedReasonArray.push(
                      '「' +
                        material.product_name +
                        '」の原価情報の内容量・入数が登録待ちです。',
                    )
                  }
                  if (
                    bread.not_cost_calculated_reasons[materialId]
                      .notMaterialCostData
                  ) {
                    notCostCalculatedReasonArray.push(
                      '「' +
                        material.product_name +
                        '」の原価情報が入力されていません。',
                    )
                  }
                }
              },
            ),
          )
        }
        if (notAmountDoughBeforeBakingFlg) {
          notCostCalculatedReasonArray.push(
            'レシピの「商品1個あたりの重量(焼成前生地量)」が入力されていません。',
          )
        }
        if (bread.backing_sticker_status !== BackingStickerStatus.OK) {
          notCostCalculatedReasonArray.push(
            'レシピが未入力、または算出中です。',
          )
        }
        if (
          bread.daseruno_status !== BreadStatus.PUBLIC ||
          bread.daseruno_recipe_status !== BreadStatus.PUBLIC
        ) {
          notCostCalculatedReasonArray.push('レシピが下書き状態です。')
        }
        if (
          bread.not_cost_calculated_recipe_reasons &&
          bread.not_cost_calculated_recipe_reasons.length > 0
        ) {
          notCostCalculatedReasonArray.push(
            bread.not_cost_calculated_recipe_reasons.join('<br>'),
          )
        }
        notCostCalculatedReasonTextMap.set(
          bread.id,
          Array.from(new Set(notCostCalculatedReasonArray)).join('<br>'),
        )
        setNotCostCalculatedReasonTextMap(notCostCalculatedReasonTextMap)
      }),
    )
    setCurrentBreads(breads)
    createBreadList(breads, notCostCalculatedReasonTextMap)
    dispatch(setIsLoading({ isLoading: false }))
  }, [dispatch])
  const createBreadList = useCallback(
    (
      breads: BreadType[],
      notCostCalculatedReasonTextMap: Map<string, string>,
    ) => {
      const breadList: TableBody[][] = []
      const csvList: CsvOutputInfo[] = []
      breads.forEach((bread) => {
        const breadColList: TableBody[] = []
        const csvInfo: CsvOutputInfo = {
          breadId: bread.id,
          breadName: bread.name,
          sellingPrice: '',
          costPrice: '',
          costRate: '',
        }

        breadColList.push({
          category: 'link',
          active: bread.is_salling,
          elementInfo: {
            link: {
              label: bread.name,
              to: '/bread/detail/' + bread.id,
            },
          },
        })
        const sellingPrice = bread.selling_price
          ? Math.floor(Number(bread.selling_price) * TAX)
          : 0
        breadColList.push({
          category: 'price',
          active: bread.is_salling,
          elementInfo: {
            text: {
              label: bread.selling_price ? String(sellingPrice) + '円' : '円',
            },
          },
        })
        csvInfo.sellingPrice = String(sellingPrice)
        if (
          (bread.cost_price || bread.cost_price === 0) &&
          !notCostCalculatedReasonTextMap.get(bread.id)
        ) {
          const costPrice = Math.floor(Number(bread.cost_price))
          breadColList.push({
            category: 'price',
            active: bread.is_salling,
            elementInfo: {
              text: {
                // cost_price内に消費税が含まれている
                label: String(costPrice) + '円',
              },
            },
          })
          csvInfo.costPrice = String(costPrice)
        } else {
          const notCostCalculatedReasonText =
            notCostCalculatedReasonTextMap.get(bread.id)
          const notCostCalculatedReasonTextView = notCostCalculatedReasonText
            ? notCostCalculatedReasonText
            : 'レシピが未入力、または算出中です。'
          breadColList.push({
            category: 'tooltip',
            active: bread.is_salling,
            elementInfo: {
              tooltip: {
                // cost_priceがない場合もしくは非算出理由がある場合はtooltip画像を表示
                icon: alertIcon,
                color: 'red',
                value: notCostCalculatedReasonTextView,
                key: bread.id,
              },
            },
          })
          csvInfo.notCostCalculatedReasonText =
            notCostCalculatedReasonTextView.replace(/<br>/g, '')
        }
        const costDiff = String(
          Math.abs(
            Math.floor(
              Number(bread.cost_price) - Number(bread.pre_change_cost_price),
            ),
          ),
        )
        const costDiffView = !notCostCalculatedReasonTextMap.get(bread.id)
          ? (bread.cost_price || bread.cost_price === 0) &&
            (bread.pre_change_cost_price || bread.pre_change_cost_price === 0)
            ? Number(bread.cost_price) - Number(bread.pre_change_cost_price) >=
              0
              ? '+ ' + costDiff + '円'
              : '- ' + costDiff + '円'
            : '+0円'
          : ''
        breadColList.push({
          category: 'price',
          active: bread.is_salling,
          elementInfo: {
            text: {
              // cost_price内に消費税が含まれている
              label: costDiffView,
            },
          },
          color:
            (bread.cost_price || bread.cost_price === 0) &&
            (bread.pre_change_cost_price || bread.pre_change_cost_price === 0)
              ? Number(bread.cost_price) -
                  Number(bread.pre_change_cost_price) >=
                0
                ? 'red'
                : 'blue'
              : 'red',
        })
        const costRate =
          (bread.selling_price || bread.selling_price !== 0) &&
          (bread.cost_price || bread.cost_price === 0)
            ? Math.floor(
                (Number(bread.cost_price) /
                  (Number(bread.selling_price) * TAX)) *
                  100,
              )
            : null
        const costRateView =
          (costRate || costRate === 0) &&
          !notCostCalculatedReasonTextMap.get(bread.id)
            ? String(costRate) + '%'
            : ''
        breadColList.push({
          category: 'price',
          active: bread.is_salling,
          elementInfo: {
            text: {
              label: costRateView,
            },
          },
        })
        csvInfo.costRate = costRate ? String(costRate) : ''
        const preChangeCostRate =
          (bread.selling_price || bread.selling_price !== 0) &&
          (bread.pre_change_cost_price || bread.pre_change_cost_price === 0)
            ? Math.floor(
                (Number(bread.pre_change_cost_price) /
                  (Number(bread.selling_price) * TAX)) *
                  100,
              )
            : null
        const costRateChangeView = !notCostCalculatedReasonTextMap.get(bread.id)
          ? (costRate || costRate === 0) &&
            (preChangeCostRate || preChangeCostRate === 0)
            ? costRate - preChangeCostRate >= 0
              ? '+ ' + String(Math.abs(costRate - preChangeCostRate)) + '%'
              : '- ' + String(Math.abs(costRate - preChangeCostRate)) + '%'
            : '+0%'
          : ''
        breadColList.push({
          category: 'price',
          active: bread.is_salling,
          elementInfo: {
            text: {
              label: costRateChangeView,
            },
          },
          color:
            costRate && preChangeCostRate
              ? costRate - preChangeCostRate >= 0
                ? 'red'
                : 'blue'
              : 'red',
        })
        breadColList.push({
          category: 'form_unit',
          active: bread.is_salling,
          elementInfo: {
            formUnit: {
              value:
                bread.cost_price || bread.cost_price === 0
                  ? formData.get(bread.id + '*simulate_selling_price')
                    ? String(formData.get(bread.id + '*simulate_selling_price'))
                    : formData.get(bread.id + '*simulate_cost_rate')
                    ? String(
                        Math.round(
                          Number(bread.cost_price) /
                            (Number(
                              formData.get(bread.id + '*simulate_cost_rate'),
                            ) /
                              100),
                        ),
                      )
                    : ''
                  : '',
              placeholder: '0',
              unit: '円',
              breadId: bread.id,
              key: 'simulate_selling_price',
              readOnly:
                bread.cost_price &&
                !notCostCalculatedReasonTextMap.get(bread.id)
                  ? false
                  : true,
            },
          },
        })
        breadColList.push({
          category: 'form_unit',
          active: bread.is_salling,
          elementInfo: {
            formUnit: {
              value:
                bread.cost_price || bread.cost_price === 0
                  ? formData.get(bread.id + '*simulate_cost_rate')
                    ? String(formData.get(bread.id + '*simulate_cost_rate'))
                    : formData.get(bread.id + '*simulate_selling_price')
                    ? String(
                        Math.round(
                          (Number(bread.cost_price) /
                            Number(
                              formData.get(
                                bread.id + '*simulate_selling_price',
                              ),
                            )) *
                            100 *
                            10,
                        ) / 10,
                      )
                    : ''
                  : '',
              placeholder: '0',
              unit: '%',
              breadId: bread.id,
              key: 'simulate_cost_rate',
              readOnly:
                bread.cost_price &&
                !notCostCalculatedReasonTextMap.get(bread.id)
                  ? false
                  : true,
            },
          },
        })
        // breadList.push([
        //   {
        //     category: 'form_unit',
        //     active: bread.is_salling,
        //     elementInfo: {
        //       formUnit: {
        //         value: formData.get(bread.id + '*updated_selling_price')
        //           ? String(formData.get(bread.id + '*updated_selling_price'))
        //           : '',
        //         placeholder: '0,000',
        //         unit: '円',
        //         breadId: bread.id,
        //         key: 'updated_selling_price',
        //       },
        //     },
        //   },
        //   {
        //     category: 'form_unit',
        //     active: bread.is_salling,
        //     elementInfo: {
        //       formUnit: {
        //         value: formData.get(bread.id + '*updated_cost_rate')
        //           ? String(formData.get(bread.id + '*updated_cost_rate'))
        //           : '',
        //         placeholder: '0',
        //         unit: '%',
        //         breadId: bread.id,
        //         key: 'updated_cost_rate',
        //       },
        //     },
        //   },
        //   {
        //     category: 'form_unit',
        //     active: bread.is_salling,
        //     elementInfo: {
        //       formUnit: {
        //         // 初期値は30%
        //         value: bread.ideal_cost_rate
        //           ? String(bread.ideal_cost_rate * 100)
        //           : formData.get(bread.id + '*ideal_cost_rate')
        //           ? String(formData.get(bread.id + '*ideal_cost_rate'))
        //           : '30',
        //         placeholder: '0',
        //         unit: '%',
        //         breadId: bread.id,
        //         key: 'ideal_cost_rate',
        //       },
        //     },
        //   },
        //   {
        //     category: 'price',
        //     active: bread.is_salling,
        //     elementInfo: {
        //       text: {
        //         label:
        //           bread.cost_price &&
        //           formData.get(bread.id + '*ideal_cost_rate')
        //             ? String(
        //                 Math.floor(
        //                   (100 * Number(bread.cost_price)) /
        //                     (100 -
        //                       Number(
        //                         formData.get(bread.id + '*ideal_cost_rate'),
        //                       )),
        //                 ),
        //               ) + '円'
        //             : bread.cost_price
        //             ? // 初期値は理想原価率30%から算出
        //               String(
        //                 Math.floor((100 * Number(bread.cost_price)) / 70),
        //               ) + '円'
        //             : '円',
        //       },
        //     },
        //   },
        // ])
        breadList.push(breadColList)
        csvList.push(csvInfo)
      })
      setBreadList(breadList)
      setCsvList(csvList)
    },
    [breads, formData, notCostCalculatedReasonTextMap],
  )

  const sortBread = async (
    desc: boolean,
    costPrice: boolean,
    sallingPrice: boolean,
    costRate: boolean,
    costPriceDiff: boolean,
    costRateDiff: boolean,
  ) => {
    const newBreads = [...currentBreads]
    if (costPrice) {
      newBreads.sort((first, second) => {
        if (first.cost_price !== 0 && !first.cost_price) {
          return desc ? 1 : -1
        }
        if (second.cost_price !== 0 && !second.cost_price) {
          return desc ? -1 : 1
        }
        return desc
          ? second.cost_price - first.cost_price
          : first.cost_price - second.cost_price
      })
    }
    if (sallingPrice) {
      newBreads.sort((first, second) => {
        if (first.selling_price !== 0 && !first.selling_price) {
          return desc ? 1 : -1
        }
        if (second.selling_price !== 0 && !second.selling_price) {
          return desc ? -1 : 1
        }
        return desc
          ? second.selling_price - first.selling_price
          : first.selling_price - second.selling_price
      })
    }
    if (costRate) {
      newBreads.sort((first, second) => {
        if (
          (first.cost_price !== 0 && !first.cost_price) ||
          (first.selling_price !== 0 && !first.selling_price)
        ) {
          return desc ? 1 : -1
        }
        if (
          (second.cost_price !== 0 && !second.cost_price) ||
          (second.selling_price !== 0 && !second.selling_price)
        ) {
          return desc ? -1 : 1
        }
        const firstCostRate = Math.floor(
          ((Number(first.selling_price) * TAX - Number(first.cost_price)) /
            (Number(first.selling_price) * TAX)) *
            100,
        )
        const secondCostRate = Math.floor(
          ((Number(second.selling_price) * TAX - Number(second.cost_price)) /
            (Number(second.selling_price) * TAX)) *
            100,
        )
        return desc
          ? secondCostRate - firstCostRate
          : firstCostRate - secondCostRate
      })
    }
    if (costRateDiff) {
      newBreads.sort((first, second) => {
        if (
          (first.cost_price !== 0 && !first.cost_price) ||
          (first.pre_change_cost_price !== 0 && !first.pre_change_cost_price) ||
          (first.selling_price !== 0 && !first.selling_price)
        ) {
          return desc ? 1 : -1
        }
        if (
          (second.cost_price !== 0 && !second.cost_price) ||
          (second.pre_change_cost_price !== 0 &&
            !second.pre_change_cost_price) ||
          (second.selling_price !== 0 && !second.selling_price)
        ) {
          return desc ? -1 : 1
        }
        const firstCostRate =
          first.selling_price && first.cost_price
            ? Math.floor(
                (Number(first.cost_price) /
                  (Number(first.selling_price) * TAX)) *
                  100,
              )
            : 0
        const firstPreChangeCostRate =
          first.selling_price && first.pre_change_cost_price
            ? Math.floor(
                (Number(first.pre_change_cost_price) /
                  (Number(first.selling_price) * TAX)) *
                  100,
              )
            : 0
        const secondCostRate =
          second.selling_price && second.cost_price
            ? Math.floor(
                (Number(second.cost_price) /
                  (Number(second.selling_price) * TAX)) *
                  100,
              )
            : 0
        const secondPreChangeCostRate =
          second.selling_price && second.pre_change_cost_price
            ? Math.floor(
                (Number(second.pre_change_cost_price) /
                  (Number(second.selling_price) * TAX)) *
                  100,
              )
            : 0
        const firstCostRateDiff = firstCostRate - firstPreChangeCostRate
        const secondCostRateDiff = secondCostRate - secondPreChangeCostRate
        return desc
          ? secondCostRateDiff - firstCostRateDiff
          : firstCostRateDiff - secondCostRateDiff
      })
    }
    if (costPriceDiff) {
      newBreads.sort((first, second) => {
        if (
          (first.cost_price !== 0 && !first.cost_price) ||
          (first.pre_change_cost_price !== 0 && !first.pre_change_cost_price)
        ) {
          return desc ? 1 : -1
        }
        if (
          (second.cost_price !== 0 && !second.cost_price) ||
          (second.pre_change_cost_price !== 0 && !second.pre_change_cost_price)
        ) {
          return desc ? -1 : 1
        }
        const firstCostPriceDiff = Math.floor(
          Number(first.cost_price) - Number(first.pre_change_cost_price),
        )
        const secondCostPriceDiff = Math.floor(
          Number(second.cost_price) - Number(second.pre_change_cost_price),
        )
        return desc
          ? secondCostPriceDiff - firstCostPriceDiff
          : firstCostPriceDiff - secondCostPriceDiff
      })
    }
    setCurrentBreads(newBreads)
    createBreadList(newBreads, notCostCalculatedReasonTextMap)
  }

  // const handleSubmmit = async () => {
  //   dispatch(setIsLoading({ isLoading: true }))
  //   await Promise.all(
  //     Array.from(formData.entries()).map(async ([k, v]) => {
  //       const kl = k.split('*')
  //       const breadId = kl[0]
  //       const key = kl[1]
  //       if (key === 'updated_selling_price' && (v || v === '0')) {
  //         await dispatch(
  //           updateSellingPrice({
  //             id: breadId,
  //             selling_price: Math.ceil(Number(v) / TAX),
  //           }),
  //         )
  //       }
  //       if (key === 'ideal_cost_rate' && (v || v === '0')) {
  //         await dispatch(
  //           updateIdealCostRate({
  //             id: breadId,
  //             ideal_cost_rate: Number(v) / 100,
  //           }),
  //         )
  //       }
  //     }),
  //   )
  //   const breadsPayload = await dispatch(getBreads())
  //   const breads = breadsPayload.payload
  //   createBreadList(breads, notCostCalculatedReasonTextMap)
  //   dispatch(setIsLoading({ isLoading: false }))
  //   window.location.reload()
  // }

  useEffect(() => {
    dispatchGetBreads()
  }, [])

  const { staff, adminShopId } = useSelector(
    (state: RootState) => state.staffSlice,
  )
  useEffect(() => {
    dispatchGetBreads()
  }, [adminShopId])

  useEffect(() => {
    createBreadList(currentBreads, notCostCalculatedReasonTextMap)
  }, [currentBreads, formData])

  const handlerCsvDownloadButton = async (
    e:
      | MouseEvent<HTMLButtonElement, MouseEvent>
      | MouseEvent<HTMLButtonElement>
      | undefined,
    format: 'xlsx' | 'csv',
  ) => {
    if (!e) {
      return
    }
    e.preventDefault()

    const workbook = new ExcelJS.Workbook()
    workbook.addWorksheet('sheet1')
    const worksheet = workbook.getWorksheet('sheet1')

    const columns = [
      { header: 'ID', key: 'breadId' },
      { header: '商品名', key: 'breadName' },
      { header: '現売価(税込)', key: 'sellingPrice' },
      { header: '原価(税込)', key: 'costPrice' },
      { header: '原価率(%)', key: 'costRate' },
      { header: '未算出理由', key: 'notCostCalculatedReasonText' },
    ]
    worksheet.columns = columns

    worksheet.addRows(csvList)

    const uint8Array =
      format === 'xlsx'
        ? await workbook.xlsx.writeBuffer()
        : await workbook.csv.writeBuffer()
    const bom = new Uint8Array([0xef, 0xbb, 0xbf])
    const blob = new Blob([bom, uint8Array], {
      type: 'application/octet-binary',
    })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = '原価情報.' + format
    a.click()
    a.remove()
  }

  return (
    <div className={styles.container}>
      <PageHedding title="原価一覧" to="" />

      <div className={styles.dashboardSearchRow}>
        <InputTextWithLightLabel
          labelName="フリーワード検索"
          description=""
          line={1}
          type="text"
          size="middle"
          value={searchWord}
          placeholder="検索ワードを入力"
          onChange={(event) => handleSearchWord(event.target.value)}
        />
        <div className={styles.breadCategoryContainer}>
          <SelectCheckBoxWithLightLabel
            labelName="商品カテゴリーを選ぶ"
            description=""
            line={1}
            size="xlarge"
            value={displaySelectBoxPlaceholder}
            displaySelectBoxValue={displaySelectBoxValue}
            onBlur={() => {}}
            onFocus={() => {}}
            checkInfos={[
              {
                isChecked: categoryIsChecked0,
                label:
                  categories[0] && categories[0].name ? categories[0].name : '',
                size: 'semi_middle',
                type: 'default',
                onChange: () => handleBreadCategory(0),
              },
              {
                isChecked: categoryIsChecked1,
                label:
                  categories[1] && categories[1].name ? categories[1].name : '',
                size: 'semi_middle',
                type: 'default',
                onChange: () => handleBreadCategory(1),
              },
              {
                isChecked: categoryIsChecked2,
                label:
                  categories[2] && categories[2].name ? categories[2].name : '',
                size: 'semi_middle',
                type: 'default',
                onChange: () => handleBreadCategory(2),
              },
              {
                isChecked: categoryIsChecked3,
                label:
                  categories[3] && categories[3].name ? categories[3].name : '',
                size: 'semi_middle',
                type: 'default',
                onChange: () => handleBreadCategory(3),
              },
              {
                isChecked: categoryIsChecked4,
                label:
                  categories[4] && categories[4].name ? categories[4].name : '',
                size: 'middle',
                type: 'default',
                onChange: () => handleBreadCategory(4),
              },
              {
                isChecked: categoryIsChecked5,
                label:
                  categories[5] && categories[5].name ? categories[5].name : '',
                size: 'semi_middle',
                type: 'default',
                onChange: () => handleBreadCategory(5),
              },
              {
                isChecked: categoryIsChecked6,
                label:
                  categories[6] && categories[6].name ? categories[6].name : '',
                size: 'semi_middle',
                type: 'default',
                onChange: () => handleBreadCategory(6),
              },
              {
                isChecked: categoryIsChecked7,
                label:
                  categories[7] && categories[7].name ? categories[7].name : '',
                size: 'semi_middle',
                type: 'default',
                onChange: () => handleBreadCategory(7),
              },
              {
                isChecked: categoryIsChecked8,
                label:
                  categories[8] && categories[8].name ? categories[8].name : '',
                size: 'semi_middle',
                type: 'default',
                onChange: () => handleBreadCategory(8),
              },
              {
                isChecked: categoryIsChecked9,
                label:
                  categories[9] && categories[9].name ? categories[9].name : '',
                size: 'semi_middle',
                type: 'default',
                onChange: () => handleBreadCategory(9),
              },
            ]}
          />
        </div>
      </div>

      <div className={styles.pattern_head}>
        <RadioButtonFilterWithLightLabel
          labelName="店頭販売状況"
          description=""
          line={1}
          radioSize="small"
          flex={true}
          isChecked={!breadSaling && !breadSalesSuspension}
          onChange={handleBreadSalesAll}
          checkInfos={[
            {
              isChecked: breadSaling,
              label: '販売中',
              onChange: handleBreadSaling,
            },
            {
              isChecked: breadSalesSuspension,
              label: '販売停止',
              onChange: handleBreadSalesSuspension,
            },
          ]}
        />
        <div className={styles.dashboardSearchRowSection_middle}></div>
        <RadioButtonFilterWithLightLabel
          labelName="原価入力状況"
          description=""
          line={1}
          radioSize="small"
          flex={true}
          isChecked={!costInputComplete && !costInputNotComplete}
          onChange={handleCostInputCompleteAll}
          checkInfos={[
            {
              isChecked: costInputComplete,
              label: '算出可',
              onChange: handleCostInputComplete,
            },
            {
              isChecked: costInputNotComplete,
              label: '算出不可',
              onChange: handleCostInputNotComplete,
            },
          ]}
        />
        <div className={styles.pattern_margin_large}></div>
      </div>

      <Table
        headers={[
          {
            category: 'name',
            elementInfo: {
              text: {
                label: '商品名',
              },
            },
          },
          {
            category: 'price',
            elementInfo: {
              text: {
                label: '現売価(税込)',
                sortAscSubmit: () =>
                  sortBread(false, false, true, false, false, false),
                sortDescSubmit: () =>
                  sortBread(true, false, true, false, false, false),
              },
            },
          },
          {
            category: 'price',
            elementInfo: {
              text: {
                label: '原価(税込)',
                sortAscSubmit: () =>
                  sortBread(false, true, false, false, false, false),
                sortDescSubmit: () =>
                  sortBread(true, true, false, false, false, false),
              },
            },
          },
          {
            category: 'price',
            elementInfo: {
              text: {
                label: '原価変動',
                sortAscSubmit: () =>
                  sortBread(false, false, false, false, true, false),
                sortDescSubmit: () =>
                  sortBread(true, false, false, false, true, false),
              },
            },
          },
          {
            category: 'price',
            elementInfo: {
              text: {
                label: '原価率',
                sortAscSubmit: () =>
                  sortBread(false, false, false, true, false, false),
                sortDescSubmit: () =>
                  sortBread(true, false, false, true, false, false),
              },
            },
          },
          {
            category: 'price',
            elementInfo: {
              text: {
                label: '原価率変動',
                sortAscSubmit: () =>
                  sortBread(false, false, false, false, false, true),
                sortDescSubmit: () =>
                  sortBread(true, false, false, false, false, true),
              },
            },
          },
          {
            category: 'form_unit',
            elementInfo: {
              text: {
                label: '売価シミュレーション',
              },
            },
          },
          {
            category: 'form_unit',
            elementInfo: {
              text: {
                label: '原価率シミュレーション',
              },
            },
          },
        ]}
        bodies={breadList}
        handleFormUnit={handleFormUnit}
      />
      <div style={{ marginTop: 32 }}>
        <SubmitButton
          label="CSV形式で書き出す"
          color="orange"
          size="large"
          icon="none"
          onClick={(e) => handlerCsvDownloadButton(e, 'csv')}
        />
      </div>

      {/* <div className={styles.dashboardButtonWrapContainer}>
        <div className={styles.dashboardButtonContainer}>
          <SubmitButton
            label="反映"
            color="orange"
            size="large"
            icon="none"
            onClick={handleSubmmit}
          />
        </div>
      </div> */}
    </div>
  )
}

export default BreadCostTemplate
