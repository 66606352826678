import { FC, useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import styles from 'styles/components/template.module.scss'
import PageHedding from 'components/PageHedding/PageHedding'
import BreadAddTabHeader from 'components/Tab/BreadAddTabHeader'
import TextAreaWithLightLabel from 'components/Form/WithLightLabel/TextArea'
import InputTextAndUnitWithLightLabel from 'components/Form/WithLightLabel/InputTextAndUnit'
import InputTextSuggestWithLightLabel from 'components/Form/WithLightLabel/InputTextSuggest'
import { suggestInfo } from 'components/Form/WithLabel/InputTextSuggest'
import AddFormProductInformation, {
  ProductInformation,
} from 'components/Form/AddForm/ProductInformation'
import SubmitButton from 'components/Button/SubmitButton'
import RadioButton from 'components/Form/RadioButton'
import {
  getBreadById,
  BreadStatus,
  BreadType,
  updateIsCostCalculating,
  updateIngredientNameForSeal,
} from 'reducks/bread/slice'
import { getMaterialById, MaterialType } from 'reducks/material/slice'
import { DoughType, getDoughById } from 'reducks/dough/slice'
import { FillingType, getFillingById } from 'reducks/filling/slice'
import {
  getRecipeByBreadId,
  createRecipe,
  updateRecipe,
  RecipeType,
  clearRecipe,
  getRecipeById,
} from 'reducks/recipe/slice'
import { DataType } from 'reducks/business/slice'
import {
  getMaterialSubCategoryById,
  MaterialSubCategoryType,
} from 'reducks/materialSubCategory/slice'
import { RootState } from 'reducks/reducers'
import slack, { NotifyPettern } from 'util/slack'
import { setIsLoading } from 'reducks/loading/slice'
import Big from 'big.js'
import { httpsCallable } from 'firebase/functions'
import { functions } from 'fb/index'
import clearCrossIcon from 'images/Form/clear-cross-icon.png'

const BreadAddTemplate: FC = () => {
  const dispatch = useDispatch<any>()
  const navigate = useNavigate()
  const location = useLocation()
  const search = useLocation().search
  // 更新の場合か確認
  const path = location.pathname
  const breadId = path.split('/bread/edit/recipe/')[1]
    ? path.split('/bread/edit/recipe/')[1]
    : null
  const query = new URLSearchParams(search)
  const copyBreadId = query.get('copyBreadId')
  const [recipeId, setRecipeId] = useState<string | null>(null)
  const [doughPattern, setDoughPattern] = useState<string>('')
  const [doughPatternName, setDoughPatternName] = useState<string>('')
  const [doughProductInformations, setDoughProductInformations] = useState<
    ProductInformation[]
  >([
    {
      productName: '',
      manufacturerName: '',
      categoryName: '',
      middleKneadPercent: '',
      authenticKneadPercent: '',
      specifyByPiece: '',
      materialId: '',
      doughId: '',
      fillingId: '',
      recipeId: '',
    },
  ])
  const [fillingPattern, setFillingPattern] = useState<string>('')
  const [fillingPatternName, setFillingPatternName] = useState<string>('')
  const [fillingProductInformations, setFillingProductInformations] = useState<
    ProductInformation[]
  >([
    {
      productName: '',
      manufacturerName: '',
      categoryName: '',
      middleKneadPercent: '',
      authenticKneadPercent: '',
      specifyByPiece: '',
      materialId: '',
      doughId: '',
      fillingId: '',
      recipeId: '',
    },
  ])
  const [recipePattern, setRecipePattern] = useState<string>('')
  const [recipePatternName, setRecipePatternName] = useState<string>('')
  const [recipeProductInformations, setRecipeProductInformations] = useState<
    ProductInformation[]
  >([])
  const [memo, setMemo] = useState<string>('')
  const [process, setProcess] = useState<string>('')
  const [breadName, setBreadName] = useState<string>('')
  const [recipeStatus, setRecipeStatus] = useState<string>('')
  const [breadDisplayPercent, setBreadDisplayPercent] = useState<boolean>(true)
  const [breadDisplayGram, setBreadDisplayGram] = useState<boolean>(false)

  const [amountDoughBeforeBaking, setAmountDoughBeforeBaking] =
    useState<string>('')
  const [lossRate, setLossRate] = useState<string>('')
  const [burnoutRate, setBurnoutRate] = useState<string>('')
  const [amountDoughAfterBaking, setAmountDoughAfterBaking] =
    useState<string>('')

  const [afterBakingPercent, setAfterBakingPercent] = useState<boolean>(true)
  const [afterBakingGram, setAfterBakingGram] = useState<boolean>(false)

  const handleDoughPattern = (doughPattern: string) => {
    setDoughPattern(doughPattern)
  }
  const handleDoughPatternName = (doughPatternName: string) => {
    setDoughPatternName(doughPatternName)
  }
  const handleFillingPattern = (fillingPattern: string) => {
    setFillingPattern(fillingPattern)
  }
  const handleFillingPatternName = (fillingPatternName: string) => {
    setFillingPatternName(fillingPatternName)
  }
  const handleRecipePattern = (recipePattern: string) => {
    setRecipePattern(recipePattern)
  }
  const handleRecipePatternName = (recipePatternName: string) => {
    setRecipePatternName(recipePatternName)
  }
  const handleDoughProductInformation = (
    productInformations: ProductInformation[],
  ) => {
    setDoughProductInformations(productInformations)
  }
  const handleFillingProductInformation = (
    productInformations: ProductInformation[],
  ) => {
    setFillingProductInformations(productInformations)
  }
  const handleRecipeProductInformation = (
    productInformations: ProductInformation[],
  ) => {
    setRecipeProductInformations(productInformations)
  }
  const handleMemo = (memo: string) => {
    setMemo(memo)
  }
  const handleProcess = (process: string) => {
    setProcess(process)
  }
  const handleBreadDisplayUnit = () => {
    setBreadDisplayPercent(!breadDisplayPercent ? true : false)
    setBreadDisplayGram(!breadDisplayGram ? true : false)
  }
  const handleAfterBakingUnit = () => {
    setAfterBakingPercent(!afterBakingPercent ? true : false)
    setAfterBakingGram(!afterBakingGram ? true : false)
  }
  const handleAmountDoughBeforeBaking = (amountDoughBeforeBaking: string) => {
    // 半角数字に変換
    amountDoughBeforeBaking = amountDoughBeforeBaking.replace(
      /[０-９]/g,
      (s) => {
        return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
      },
    )
    if (
      !Number(amountDoughBeforeBaking) &&
      Number(amountDoughBeforeBaking) !== 0
    ) {
      return
    }
    setAmountDoughBeforeBaking(amountDoughBeforeBaking)
    setAmountDoughBeforeBakingErrorText(null)
  }
  const handleLossRate = (lossRate: string) => {
    // 半角数字に変換
    lossRate = lossRate.replace(/[０-９]/g, (s) => {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
    })
    if (!Number(lossRate) && Number(lossRate) !== 0) {
      return
    }
    setLossRate(lossRate)
    setLossRateErrorText(null)
  }
  const handleBurnoutRate = (burnoutRate: string) => {
    // 半角数字に変換
    burnoutRate = burnoutRate.replace(/[０-９]/g, (s) => {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
    })
    if (!Number(burnoutRate) && Number(burnoutRate) !== 0) {
      return
    }
    setBurnoutRate(burnoutRate)
    setBurnoutRateErrorText(null)
  }
  const handleAmountDoughAfterBaking = (amountDoughAfterBaking: string) => {
    // 半角数字に変換
    amountDoughAfterBaking = amountDoughAfterBaking.replace(/[０-９]/g, (s) => {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
    })
    if (
      !Number(amountDoughAfterBaking) &&
      Number(amountDoughAfterBaking) !== 0
    ) {
      return
    }
    setAmountDoughAfterBaking(amountDoughAfterBaking)
    setAmountDoughAfterBakingErrorText(null)
  }
  const handleCalcAmountDoughbeforeBaking = () => {
    if (!breadDisplayGram) {
      return
    }
    const recipeGramSum = recipeProductInformations.reduce(
      (sum, recipeProductInformation) =>
        new Big(Number(recipeProductInformation.middleKneadPercent))
          .plus(Number(recipeProductInformation.authenticKneadPercent))
          .plus(Number(sum))
          .toNumber(),
      0,
    )
    const doughGramSum = doughProductInformations.reduce(
      (sum, doughProductInformation) =>
        new Big(Number(doughProductInformation.middleKneadPercent))
          .plus(Number(doughProductInformation.authenticKneadPercent))
          .plus(Number(sum))
          .toNumber(),
      0,
    )
    const fillingGramSum = fillingProductInformations.reduce(
      (sum, fillingProductInformation) =>
        new Big(Number(fillingProductInformation.middleKneadPercent))
          .plus(Number(fillingProductInformation.authenticKneadPercent))
          .plus(Number(sum))
          .toNumber(),
      0,
    )
    setAmountDoughBeforeBaking(
      String(
        new Big(Number(recipeGramSum))
          .plus(Number(doughGramSum))
          .plus(Number(fillingGramSum))
          .toNumber(),
      ),
    )
  }

  const handleAddDoughPattern = async () => {
    const newDoughProductInformations = [...doughProductInformations]
    if (
      newDoughProductInformations.length === 1 &&
      newDoughProductInformations[0].productName === '' &&
      newDoughProductInformations[0].manufacturerName === '' &&
      newDoughProductInformations[0].middleKneadPercent === '' &&
      newDoughProductInformations[0].authenticKneadPercent === ''
    ) {
      newDoughProductInformations.shift()
    }
    const doughPayload = await dispatch(getDoughById({ id: doughPattern }))
    const dough: DoughType = doughPayload.payload
    newDoughProductInformations.push({
      productName: dough.pettern_name,
      manufacturerName: '',
      categoryName: '生地パターン',
      middleKneadPercent: '',
      authenticKneadPercent: '',
      specifyByPiece: '',
      materialId: '',
      doughId: dough.id,
      fillingId: '',
      recipeId: '',
    })
    setDoughProductInformations(newDoughProductInformations)
    clearDoughSuggest()
  }
  const handleAddRecipePattern = async () => {
    const newRecipeProductInformations = [...recipeProductInformations]
    if (
      newRecipeProductInformations.length === 1 &&
      newRecipeProductInformations[0].productName === '' &&
      newRecipeProductInformations[0].manufacturerName === '' &&
      newRecipeProductInformations[0].middleKneadPercent === '' &&
      newRecipeProductInformations[0].authenticKneadPercent === ''
    ) {
      newRecipeProductInformations.shift()
    }
    const recipePayload = await dispatch(getRecipeById({ id: recipePattern }))
    const recipe: RecipeType = recipePayload.payload
    newRecipeProductInformations.push({
      productName: recipe.recipe_name,
      manufacturerName: '',
      categoryName: '商品',
      middleKneadPercent: '',
      authenticKneadPercent: '',
      specifyByPiece: '',
      materialId: '',
      doughId: '',
      fillingId: '',
      recipeId: recipe.id,
    })
    setRecipeProductInformations(newRecipeProductInformations)
    clearRecipeSuggest()
  }
  const handleAddFillingPattern = async () => {
    const newFillingProductInformations = [...fillingProductInformations]
    if (
      newFillingProductInformations.length === 1 &&
      newFillingProductInformations[0].productName === '' &&
      newFillingProductInformations[0].manufacturerName === '' &&
      newFillingProductInformations[0].middleKneadPercent === '' &&
      newFillingProductInformations[0].authenticKneadPercent === ''
    ) {
      newFillingProductInformations.shift()
    }
    const fillingPayload = await dispatch(
      getFillingById({ id: fillingPattern }),
    )
    const filling: FillingType = fillingPayload.payload
    newFillingProductInformations.push({
      productName: filling.pettern_name,
      manufacturerName: '',
      categoryName: 'フィリングパターン',
      middleKneadPercent: '',
      authenticKneadPercent: '',
      specifyByPiece: '',
      materialId: '',
      doughId: '',
      fillingId: filling.id,
      recipeId: '',
    })
    setFillingProductInformations(newFillingProductInformations)
    clearFillingSuggest()
  }

  const initBreadInfo = useCallback(
    async (breadId: string | null, copyBreadId: string | null) => {
      dispatch(setIsLoading({ isLoading: true }))
      if (!staff) {
        navigate('/initload?path=' + path)
        window.scrollTo(0, 0)
        dispatch(setIsLoading({ isLoading: false }))
        return
      }
      if (!breadId) {
        // 商品IDが取得できない場合はTOPに飛ばす
        navigate('/initload')
        window.scrollTo(0, 0)
        dispatch(setIsLoading({ isLoading: false }))
        return
      }
      const getBreadId: string = copyBreadId ? copyBreadId : breadId
      const breadPayload = await dispatch(getBreadById({ id: getBreadId }))
      const bread = breadPayload.payload
      if (!bread) {
        // 商品情報が取得できない場合はTOPに飛ばす
        navigate('/initload')
        window.scrollTo(0, 0)
        dispatch(setIsLoading({ isLoading: false }))
        return
      }
      // レシピに対応する商品から商品名を取得
      const currentBreadPayload = await dispatch(getBreadById({ id: breadId }))
      const currentBread = currentBreadPayload.payload
      setBreadName(currentBread ? currentBread.name : '')
      await dispatch(clearRecipe())
      const recipePayload = await dispatch(getRecipeByBreadId({ id: breadId }))
      const recipe: RecipeType = recipePayload.payload
      if (recipe) {
        setRecipeId(recipe.id)
        setAmountDoughBeforeBaking(
          recipe.amount_dough_before_baking
            ? String(recipe.amount_dough_before_baking)
            : '',
        )
        setLossRate(
          recipe.loss_rate || recipe.loss_rate === 0
            ? String(recipe.loss_rate * 100)
            : '0',
        )
        setBurnoutRate(
          recipe.burnout_rate || recipe.burnout_rate === 0
            ? String(recipe.burnout_rate * 100)
            : '0',
        )
        setAmountDoughAfterBaking(
          recipe.amount_dough_after_baking
            ? String(recipe.amount_dough_after_baking)
            : '',
        )
        setMemo(recipe.memo)
        setProcess(recipe.process)
        setRecipeStatus(
          bread.daseruno_recipe_status
            ? bread.daseruno_recipe_status
            : BreadStatus.DRAFT,
        )
        setBreadDisplayPercent(recipe.is_percent)
        setBreadDisplayGram(recipe.is_gram)
        setAfterBakingPercent(
          !recipe.is_after_baking_percent && !recipe.is_after_baking_gram
            ? true
            : recipe.is_after_baking_percent
            ? true
            : false,
        )
        setAfterBakingGram(
          !recipe.is_after_baking_percent && !recipe.is_after_baking_gram
            ? false
            : recipe.is_after_baking_gram
            ? true
            : false,
        )

        const doughProductInformations: ProductInformation[] = []
        if (recipe.dough_material_informations.length > 0) {
          await recipe.dough_material_informations.reduce(
            (promise, doughMaterialInfo) => {
              return promise.then(async () => {
                const materialId: string | null = doughMaterialInfo.material_ref
                  ? doughMaterialInfo.material_ref.id
                    ? doughMaterialInfo.material_ref.id
                    : // @ts-ignore
                      doughMaterialInfo.material_ref._key.path.segments[6]
                  : null
                const doughId: string | null = doughMaterialInfo.dough_ref
                  ? doughMaterialInfo.dough_ref.id
                    ? doughMaterialInfo.dough_ref.id
                    : // @ts-ignore
                      doughMaterialInfo.dough_ref._key.path.segments[6]
                  : null
                if (materialId) {
                  const materialPayload = await dispatch(
                    getMaterialById({ id: materialId }),
                  )
                  const material: MaterialType = materialPayload.payload
                  const materialSubCategoryPayload = await dispatch(
                    getMaterialSubCategoryById({
                      id: material.material_sub_category_ref.id,
                    }),
                  )
                  const materialSubCategory: MaterialSubCategoryType =
                    materialSubCategoryPayload.payload
                  doughProductInformations.push({
                    productName: material.product_name,
                    manufacturerName: material.manufacturer,
                    categoryName: materialSubCategory.name,
                    middleKneadPercent: String(
                      doughMaterialInfo.middle_knead_percent,
                    ),
                    authenticKneadPercent: String(
                      doughMaterialInfo.authentic_knead_percent,
                    ),
                    specifyByPiece: String(
                      doughMaterialInfo.specify_by_piece
                        ? doughMaterialInfo.specify_by_piece
                        : '',
                    ),
                    materialId: materialId,
                    doughId: '',
                    fillingId: '',
                    recipeId: '',
                  })
                } else if (doughId) {
                  const doughPayload = await dispatch(
                    getDoughById({ id: doughId }),
                  )
                  const dough: DoughType = doughPayload.payload
                  doughProductInformations.push({
                    productName: dough.pettern_name,
                    manufacturerName: '',
                    categoryName: '生地パターン',
                    middleKneadPercent: String(
                      doughMaterialInfo.middle_knead_percent,
                    ),
                    authenticKneadPercent: String(
                      doughMaterialInfo.authentic_knead_percent,
                    ),
                    specifyByPiece: String(
                      doughMaterialInfo.specify_by_piece
                        ? doughMaterialInfo.specify_by_piece
                        : '',
                    ),
                    materialId: '',
                    doughId: doughId,
                    fillingId: '',
                    recipeId: '',
                  })
                } else {
                  doughProductInformations.push({
                    productName: doughMaterialInfo.productName
                      ? doughMaterialInfo.productName
                      : '',
                    manufacturerName: doughMaterialInfo.manufacturerName
                      ? doughMaterialInfo.manufacturerName
                      : '',
                    categoryName: '',
                    middleKneadPercent: String(
                      doughMaterialInfo.middle_knead_percent,
                    ),
                    authenticKneadPercent: String(
                      doughMaterialInfo.authentic_knead_percent,
                    ),
                    specifyByPiece: String(
                      doughMaterialInfo.specify_by_piece
                        ? doughMaterialInfo.specify_by_piece
                        : '',
                    ),
                    materialId: '',
                    doughId: '',
                    fillingId: '',
                    recipeId: '',
                  })
                }
              })
            },
            Promise.resolve(),
          )
        }
        if (doughProductInformations.length === 0) {
          doughProductInformations.push({
            productName: '',
            manufacturerName: '',
            categoryName: '',
            middleKneadPercent: '',
            authenticKneadPercent: '',
            specifyByPiece: '',
            materialId: '',
            doughId: '',
            fillingId: '',
            recipeId: '',
          })
        }
        setDoughProductInformations(doughProductInformations)
        const fillingProductInformations: ProductInformation[] = []
        if (recipe.filling_material_informations.length > 0) {
          await recipe.filling_material_informations.reduce(
            (promise, fillingMaterialInfo) => {
              return promise.then(async () => {
                const materialId: string | null =
                  fillingMaterialInfo.material_ref
                    ? fillingMaterialInfo.material_ref.id
                      ? fillingMaterialInfo.material_ref.id
                      : // @ts-ignore
                        fillingMaterialInfo.material_ref._key.path.segments[6]
                    : null
                const fillingId: string | null = fillingMaterialInfo.filling_ref
                  ? fillingMaterialInfo.filling_ref.id
                    ? fillingMaterialInfo.filling_ref.id
                    : // @ts-ignore
                      fillingMaterialInfo.filling_ref._key.path.segments[6]
                  : null
                if (materialId) {
                  const materialPayload = await dispatch(
                    getMaterialById({ id: materialId }),
                  )
                  const material: MaterialType = materialPayload.payload
                  const materialSubCategoryPayload = await dispatch(
                    getMaterialSubCategoryById({
                      id: material.material_sub_category_ref.id,
                    }),
                  )
                  const materialSubCategory: MaterialSubCategoryType =
                    materialSubCategoryPayload.payload
                  fillingProductInformations.push({
                    productName: material.product_name,
                    manufacturerName: material.manufacturer,
                    categoryName: materialSubCategory.name,
                    middleKneadPercent: String(
                      fillingMaterialInfo.middle_knead_percent,
                    ),
                    authenticKneadPercent: String(
                      fillingMaterialInfo.authentic_knead_percent,
                    ),
                    specifyByPiece: String(
                      fillingMaterialInfo.specify_by_piece
                        ? fillingMaterialInfo.specify_by_piece
                        : '',
                    ),
                    materialId: materialId,
                    doughId: '',
                    fillingId: '',
                    recipeId: '',
                  })
                } else if (fillingId) {
                  const fillingPayload = await dispatch(
                    getFillingById({ id: fillingId }),
                  )
                  const filling: FillingType = fillingPayload.payload
                  fillingProductInformations.push({
                    productName: filling.pettern_name,
                    manufacturerName: '',
                    categoryName: 'フィリングパターン',
                    middleKneadPercent: String(
                      fillingMaterialInfo.middle_knead_percent,
                    ),
                    authenticKneadPercent: String(
                      fillingMaterialInfo.authentic_knead_percent,
                    ),
                    specifyByPiece: String(
                      fillingMaterialInfo.specify_by_piece
                        ? fillingMaterialInfo.specify_by_piece
                        : '',
                    ),
                    materialId: '',
                    doughId: '',
                    fillingId: fillingId,
                    recipeId: '',
                  })
                } else {
                  fillingProductInformations.push({
                    productName: fillingMaterialInfo.productName
                      ? fillingMaterialInfo.productName
                      : '',
                    manufacturerName: fillingMaterialInfo.manufacturerName
                      ? fillingMaterialInfo.manufacturerName
                      : '',
                    categoryName: '',
                    middleKneadPercent: String(
                      fillingMaterialInfo.middle_knead_percent,
                    ),
                    authenticKneadPercent: String(
                      fillingMaterialInfo.authentic_knead_percent,
                    ),
                    specifyByPiece: String(
                      fillingMaterialInfo.specify_by_piece
                        ? fillingMaterialInfo.specify_by_piece
                        : '',
                    ),
                    materialId: '',
                    doughId: '',
                    fillingId: '',
                    recipeId: '',
                  })
                }
              })
            },
            Promise.resolve(),
          )
        }
        if (fillingProductInformations.length === 0) {
          fillingProductInformations.push({
            productName: '',
            manufacturerName: '',
            categoryName: '',
            middleKneadPercent: '',
            authenticKneadPercent: '',
            specifyByPiece: '',
            materialId: '',
            doughId: '',
            fillingId: '',
            recipeId: '',
          })
        }
        setFillingProductInformations(fillingProductInformations)
        const recipeProductInformations: ProductInformation[] = []
        if (recipe.recipe_material_informations.length > 0) {
          await recipe.recipe_material_informations.reduce(
            (promise, recipeMaterialInfo) => {
              return promise.then(async () => {
                const recipeId: string | null = recipeMaterialInfo.recipe_ref
                  ? recipeMaterialInfo.recipe_ref.id
                    ? recipeMaterialInfo.recipe_ref.id
                    : // @ts-ignore
                      recipeMaterialInfo.recipe_ref._key.path.segments[6]
                  : null
                if (recipeId) {
                  const recipePayload = await dispatch(
                    getRecipeById({ id: recipeId }),
                  )
                  const recipe: RecipeType = recipePayload.payload
                  recipeProductInformations.push({
                    productName: recipe.recipe_name,
                    manufacturerName: '',
                    categoryName: '商品',
                    middleKneadPercent: String(
                      recipeMaterialInfo.middle_knead_percent,
                    ),
                    authenticKneadPercent: String(
                      recipeMaterialInfo.authentic_knead_percent,
                    ),
                    specifyByPiece: String(
                      recipeMaterialInfo.specify_by_piece
                        ? recipeMaterialInfo.specify_by_piece
                        : '',
                    ),
                    materialId: '',
                    doughId: '',
                    fillingId: '',
                    recipeId: recipeId,
                  })
                } else {
                  recipeProductInformations.push({
                    productName: recipeMaterialInfo.productName
                      ? recipeMaterialInfo.productName
                      : '',
                    manufacturerName: recipeMaterialInfo.manufacturerName
                      ? recipeMaterialInfo.manufacturerName
                      : '',
                    categoryName: '',
                    middleKneadPercent: String(
                      recipeMaterialInfo.middle_knead_percent,
                    ),
                    authenticKneadPercent: String(
                      recipeMaterialInfo.authentic_knead_percent,
                    ),
                    specifyByPiece: String(
                      recipeMaterialInfo.specify_by_piece
                        ? recipeMaterialInfo.specify_by_piece
                        : '',
                    ),
                    materialId: '',
                    doughId: '',
                    fillingId: '',
                    recipeId: '',
                  })
                }
              })
            },
            Promise.resolve(),
          )
        }
        setRecipeProductInformations(recipeProductInformations)
      }
      dispatch(setIsLoading({ isLoading: false }))
    },
    [breadId],
  )

  useEffect(() => {
    initBreadInfo(breadId, copyBreadId)
  }, [])

  const [
    amountDoughBeforeBakingErrorText,
    setAmountDoughBeforeBakingErrorText,
  ] = useState<string | null>(null)
  const [lossRateErrorText, setLossRateErrorText] = useState<string | null>(
    null,
  )
  const [burnoutRateErrorText, setBurnoutRateErrorText] = useState<
    string | null
  >(null)
  const [amountDoughAftereBakingErrorText, setAmountDoughAfterBakingErrorText] =
    useState<string | null>(null)
  const [
    doughProductInformationsErrorText,
    setDoughProductInformationsErrorText,
  ] = useState<string | null>(null)
  const [
    fillingProductInformationsErrorText,
    setFillingProductInformationsErrorText,
  ] = useState<string | null>(null)
  const [
    recipeProductInformationsErrorText,
    setRecipeProductInformationsErrorText,
  ] = useState<string | null>(null)
  const validation = () => {
    let isError = false
    if (!amountDoughBeforeBaking) {
      setAmountDoughBeforeBakingErrorText('入力されていません')
      isError = true
    }
    if (lossRate && (Number(lossRate) >= 100 || Number(lossRate) < 0)) {
      setLossRateErrorText('0〜99の範囲で入力してください')
      isError = true
    }
    if (
      burnoutRate &&
      (Number(burnoutRate) >= 100 || Number(burnoutRate) < 0)
    ) {
      setBurnoutRateErrorText('0〜99の範囲で入力してください')
      isError = true
    }
    // 焼成後重量が焼成前重量より大きい場合
    if (
      amountDoughBeforeBaking &&
      amountDoughAfterBaking &&
      afterBakingGram &&
      Number(amountDoughBeforeBaking) < Number(amountDoughAfterBaking)
    ) {
      setAmountDoughAfterBakingErrorText(
        '焼成前重量より大きい値を入力することはできません',
      )
      isError = true
    }
    doughProductInformations.map(async (productInformation) => {
      if (
        !productInformation.doughId &&
        !productInformation.fillingId &&
        !productInformation.materialId &&
        !productInformation.recipeId &&
        !productInformation.productName &&
        !productInformation.manufacturerName &&
        !productInformation.authenticKneadPercent
      ) {
        // 全情報がない場合はバリデーション通過
        return
      }
      if (!productInformation.authenticKneadPercent) {
        // 本ごね情報がない
        setDoughProductInformationsErrorText('一部の情報が入力されていません')
        isError = true
        return
      }
      if (
        (!Number(productInformation.middleKneadPercent) &&
          Number(productInformation.middleKneadPercent) !== 0) ||
        (!Number(productInformation.authenticKneadPercent) &&
          Number(productInformation.authenticKneadPercent) !== 0)
      ) {
        // 中ごね本ごね情報が不正
        setDoughProductInformationsErrorText(
          '一部の中捏・本捏情報に正しくない情報が入力されています',
        )
        isError = true
        return
      }
      if (
        !productInformation.doughId &&
        !productInformation.fillingId &&
        !productInformation.materialId &&
        !productInformation.recipeId &&
        (!productInformation.productName ||
          !productInformation.manufacturerName)
      ) {
        // 未登録の原材料の場合で、原材料名とメーカーが入力されていない
        setDoughProductInformationsErrorText('一部の情報が入力されていません')
        isError = true
        return
      }
    })
    fillingProductInformations.map(async (productInformation) => {
      if (
        !productInformation.doughId &&
        !productInformation.fillingId &&
        !productInformation.materialId &&
        !productInformation.recipeId &&
        !productInformation.productName &&
        !productInformation.manufacturerName &&
        !productInformation.authenticKneadPercent
      ) {
        // 全情報がない場合はバリデーション通過
        return
      }
      if (!productInformation.authenticKneadPercent) {
        // 本ごね情報がない
        setFillingProductInformationsErrorText('一部の情報が入力されていません')
        isError = true
        return
      }
      if (
        (!Number(productInformation.middleKneadPercent) &&
          Number(productInformation.middleKneadPercent) !== 0) ||
        (!Number(productInformation.authenticKneadPercent) &&
          Number(productInformation.authenticKneadPercent) !== 0)
      ) {
        // 中ごね本ごね情報が不正
        setFillingProductInformationsErrorText(
          '一部の中捏・本捏情報に正しくない情報が入力されています',
        )
        isError = true
        return
      }
      if (
        !productInformation.doughId &&
        !productInformation.fillingId &&
        !productInformation.materialId &&
        !productInformation.recipeId &&
        (!productInformation.productName ||
          !productInformation.manufacturerName)
      ) {
        // 未登録の原材料の場合で、原材料名とメーカーが入力されていない
        setFillingProductInformationsErrorText('一部の情報が入力されていません')
        isError = true
        return
      }
    })
    recipeProductInformations.map(async (productInformation) => {
      if (
        !productInformation.doughId &&
        !productInformation.fillingId &&
        !productInformation.materialId &&
        !productInformation.recipeId &&
        !productInformation.productName &&
        !productInformation.manufacturerName &&
        !productInformation.authenticKneadPercent
      ) {
        // 全情報がない場合はバリデーション通過
        return
      }
      if (!productInformation.authenticKneadPercent) {
        // 本ごね情報がない
        setRecipeProductInformationsErrorText('一部の情報が入力されていません')
        isError = true
        return
      }
      if (
        (!Number(productInformation.middleKneadPercent) &&
          Number(productInformation.middleKneadPercent) !== 0) ||
        (!Number(productInformation.authenticKneadPercent) &&
          Number(productInformation.authenticKneadPercent) !== 0)
      ) {
        // 中ごね本ごね情報が不正
        setRecipeProductInformationsErrorText(
          '一部の中捏・本捏情報に正しくない情報が入力されています',
        )
        isError = true
        return
      }
      if (
        !productInformation.doughId &&
        !productInformation.fillingId &&
        !productInformation.materialId &&
        !productInformation.recipeId &&
        (!productInformation.productName ||
          !productInformation.manufacturerName)
      ) {
        // 未登録の原材料の場合で、原材料名とメーカーが入力されていない
        setRecipeProductInformationsErrorText('一部の情報が入力されていません')
        isError = true
        return
      }
    })

    if (isError) {
      window.scrollTo(0, 0)
      return false
    }
    return true
  }

  const { staff } = useSelector((state: RootState) => state.staffSlice)

  const handleSubmit = async (
    to: string = '',
    isDraft: boolean = false,
    draftAlert: boolean = false,
  ) => {
    if (!breadId) {
      return
    }
    const breadPayload = await dispatch(getBreadById({ id: breadId }))
    const bread: BreadType = breadPayload.payload
    if (!bread) {
      return
    }
    if (!isDraft) {
      const is_valid = validation()
      if (!is_valid) {
        if (draftAlert) {
          if (
            window.confirm(
              '不十分な内容で保存されるため下書き状態に戻ります。よろしいですか？',
            )
          ) {
            handleSubmit(to, true)
          }
        }
        return
      }
    }
    dispatch(setIsLoading({ isLoading: true }))

    // 登録されていない原材料がある場合はSlack送信
    const productInformations = [
      ...doughProductInformations,
      ...fillingProductInformations,
      ...recipeProductInformations,
    ]
    slack.materialApply(
      productInformations,
      staff && staff.shopName ? staff.shopName : '',
      bread.name,
      NotifyPettern.RECIPE,
    )

    let createDocId: string | null = null
    if (recipeId) {
      // Firestoreを更新
      const createResult = await dispatch(
        updateRecipe({
          id: recipeId,
          dough_material_informations: doughProductInformations,
          filling_material_informations: fillingProductInformations,
          recipe_material_informations: recipeProductInformations,
          amount_dough_before_baking: Number(amountDoughBeforeBaking),
          loss_rate: lossRate || lossRate === '0' ? Number(lossRate) / 100 : 0,
          burnout_rate:
            burnoutRate || burnoutRate === '0' ? Number(burnoutRate) / 100 : 0,
          amount_dough_after_baking: amountDoughAfterBaking
            ? Number(amountDoughAfterBaking)
            : null,
          is_after_baking_percent: afterBakingPercent,
          is_after_baking_gram: afterBakingGram,
          memo: memo,
          is_percent: breadDisplayPercent,
          is_gram: breadDisplayGram,
          process: process,
          bread_id: breadId,
          recipe_name: bread.name,
          is_draft: isDraft,
        }),
      )
      createDocId = createResult.payload
    } else {
      // Firestoreに登録
      const createResult = await dispatch(
        createRecipe({
          dough_material_informations: doughProductInformations,
          filling_material_informations: fillingProductInformations,
          recipe_material_informations: recipeProductInformations,
          amount_dough_before_baking: Number(amountDoughBeforeBaking),
          loss_rate: lossRate || lossRate === '0' ? Number(lossRate) / 100 : 0,
          burnout_rate:
            burnoutRate || burnoutRate === '0' ? Number(burnoutRate) / 100 : 0,
          amount_dough_after_baking: amountDoughAfterBaking
            ? Number(amountDoughAfterBaking)
            : null,
          is_after_baking_percent: afterBakingPercent,
          is_after_baking_gram: afterBakingGram,
          memo: memo,
          process: process,
          is_percent: breadDisplayPercent,
          is_gram: breadDisplayGram,
          bread_id: breadId,
          recipe_name: bread.name,
          is_draft: isDraft,
        }),
      )
      createDocId = createResult.payload
    }
    if (!createDocId) {
      dispatch(setIsLoading({ isLoading: false }))
      return
    }
    // 原価計算中ステータスに更新
    await dispatch(
      updateIsCostCalculating({
        id: breadId,
        is_cost_calculating: true,
      }),
    )
    // 印刷用内容量情報をリセット
    await dispatch(
      updateIngredientNameForSeal({
        id: breadId,
        ingredient_name_for_seal: null,
      }),
    )
    // 裏貼りシールステータスと原価を更新する
    const updateBackSealStatusAndCostDaseruno = httpsCallable(
      functions,
      'updateBackSealStatusAndCostDaseruno',
    )
    await updateBackSealStatusAndCostDaseruno({
      docId: createDocId,
      dataType: DataType.RECIPE,
      breadId: breadId,
      shopId: staff?.shopId,
    })
    await dispatch(clearRecipe())

    if (to === 'top') {
      navigate('/initload')
      window.scrollTo(0, 0)
    }
    if (to === 'detail') {
      navigate('/bread/detail/' + breadId)
      window.scrollTo(0, 0)
    }
    if (to) {
      if (to === 'top') {
        navigate('/initload')
        window.scrollTo(0, 0)
      } else if (to === 'detail') {
        navigate('/bread/detail/' + breadId)
        window.scrollTo(0, 0)
      } else {
        navigate(to)
        window.scrollTo(0, 0)
      }
    }
    dispatch(setIsLoading({ isLoading: false }))
  }

  const handleRecipeSuggest = async (info: suggestInfo) => {
    const recipePayload = await dispatch(getRecipeById({ id: info.id }))
    const recipe: RecipeType = recipePayload.payload
    if (recipe) {
      handleRecipePattern(recipe.id)
      handleRecipePatternName(recipe.recipe_name)
    }
  }

  const clearRecipeSuggest = async () => {
    handleRecipePattern('')
    handleRecipePatternName('')
  }

  const handleDoughSuggest = async (info: suggestInfo) => {
    const doughPayload = await dispatch(getDoughById({ id: info.id }))
    const dough: DoughType = doughPayload.payload
    if (dough) {
      handleDoughPattern(dough.id)
      handleDoughPatternName(dough.pettern_name)
    }
  }

  const clearDoughSuggest = async () => {
    handleDoughPattern('')
    handleDoughPatternName('')
  }

  const handleFillingSuggest = async (info: suggestInfo) => {
    const fillingPayload = await dispatch(getFillingById({ id: info.id }))
    const filling: FillingType = fillingPayload.payload
    if (filling) {
      handleFillingPattern(filling.id)
      handleFillingPatternName(filling.pettern_name)
    }
  }

  const clearFillingSuggest = async () => {
    handleFillingPattern('')
    handleFillingPatternName('')
  }

  useEffect(() => {
    // イベントの設定
    window.history.pushState(null, document.title)
    window.addEventListener('beforeunload', onUnload)
    window.addEventListener('popstate', onBrouserBack)

    return () => {
      // イベントの設定解除
      window.removeEventListener('beforeunload', onUnload)
      window.removeEventListener('popstate', onBrouserBack)
    }
  }, [])

  const onUnload = (e: any) => {
    e.preventDefault()
    e.returnValue = ''
  }

  const onBrouserBack = () => {
    if (window.confirm('このサイトから離れてもよろしいですか？')) {
      navigate(-2)
    }
  }

  return (
    <div className={styles.container}>
      <PageHedding title="レシピ登録" to="/initload" />
      <BreadAddTabHeader
        isRecipe={true}
        toBread={'/bread/edit/' + breadId}
        toRecipe=""
        breadId={breadId}
        breadName={breadName}
        handleSubmit={() => handleSubmit('/bread/edit/' + breadId, false, true)}
      />
      <div className={styles.bread_add_recipe_display_unit}>
        <div>
          <span className={styles.bread_add_recipe_display_unit_text}>
            表示単位
          </span>
        </div>
        <RadioButton
          isChecked={breadDisplayPercent}
          label="%"
          onChange={handleBreadDisplayUnit}
        />
        <RadioButton
          isChecked={breadDisplayGram}
          label="g"
          onChange={handleBreadDisplayUnit}
        />
      </div>
      <div className={styles.bread_add_recipe}>
        <div className={styles.bread_add_recipe_section}>商品</div>
      </div>
      <div className={styles.bread_add_recipe_call_pattern}>
        <div className={styles.bread_add_recipe_call_pattern_select}>
          <InputTextSuggestWithLightLabel
            labelName="商品を呼び出す"
            description=""
            line={1}
            type="text"
            size="large"
            value={recipePatternName}
            placeholder=""
            suggestType="public_recipes"
            onSelect={handleRecipeSuggest}
            onChange={(event) => handleRecipePatternName(event.target.value)}
            readOnly={recipePattern ? true : false}
          />
          {recipePattern ? (
            <div onClick={clearRecipeSuggest}>
              <img
                className={styles.bread_add_recipe_call_pattern_select_clear}
                src={clearCrossIcon}
                alt="x"
              />
            </div>
          ) : (
            ''
          )}
          <SubmitButton
            label="追加する"
            color={recipePattern ? 'orange' : 'gray'}
            size="small"
            icon="none"
            onClick={handleAddRecipePattern}
          />
        </div>
      </div>
      <div className={styles.bread_add_recipe_product_information}>
        <AddFormProductInformation
          formInfos={recipeProductInformations}
          unit={breadDisplayPercent ? '%' : 'g'}
          errorText={recipeProductInformationsErrorText}
          handleProductInformation={handleRecipeProductInformation}
          noAddButton={true}
        />
      </div>
      <div className={styles.bread_add_recipe}>
        <div className={styles.bread_add_recipe_section}>生地</div>
      </div>
      <div className={styles.bread_add_recipe_call_pattern}>
        <div className={styles.bread_add_recipe_call_pattern_select}>
          <InputTextSuggestWithLightLabel
            labelName="生地を呼び出す"
            description=""
            line={1}
            type="text"
            size="large"
            value={doughPatternName}
            placeholder=""
            suggestType="available_doughs"
            onSelect={handleDoughSuggest}
            onChange={(event) => handleDoughPatternName(event.target.value)}
            readOnly={doughPattern ? true : false}
          />
          {doughPattern ? (
            <div onClick={clearDoughSuggest}>
              <img
                className={styles.bread_add_recipe_call_pattern_select_clear}
                src={clearCrossIcon}
                alt="x"
              />
            </div>
          ) : (
            ''
          )}
          <SubmitButton
            label="追加する"
            color={doughPattern ? 'orange' : 'gray'}
            size="small"
            icon="none"
            onClick={handleAddDoughPattern}
          />
        </div>
        <SubmitButton
          label="生地を作成する"
          color="orange"
          size="xmiddle"
          icon="plus"
          onClick={() => handleSubmit('/dough/add?recipeBreadId=' + breadId)}
        />
      </div>
      <div className={styles.bread_add_recipe_product_information}>
        <AddFormProductInformation
          formInfos={doughProductInformations}
          unit={breadDisplayPercent ? '%' : 'g'}
          errorText={doughProductInformationsErrorText}
          handleProductInformation={handleDoughProductInformation}
        />
      </div>
      <div className={styles.bread_add_recipe_sum}>
        <InputTextAndUnitWithLightLabel
          labelName="合計"
          description=""
          line={1}
          type="text"
          size="minimum"
          unit={breadDisplayPercent ? '%' : 'g'}
          readOnly={true}
          value={doughProductInformations.reduce(
            (sum, doughProductInformation) =>
              String(
                new Big(Number(doughProductInformation.middleKneadPercent))
                  .plus(Number(doughProductInformation.authenticKneadPercent))
                  .plus(Number(sum))
                  .toNumber(),
              ),
            '0',
          )}
          placeholder=""
        />
        <div className={styles.bread_add_recipe_sum_space}></div>
      </div>
      <div className={styles.bread_add_recipe}>
        <div className={styles.bread_add_recipe_section}>フィリング</div>
      </div>
      <div className={styles.bread_add_recipe_call_pattern}>
        <div className={styles.bread_add_recipe_call_pattern_select}>
          <InputTextSuggestWithLightLabel
            labelName="フィリングを呼び出す"
            description=""
            line={1}
            type="text"
            size="large"
            value={fillingPatternName}
            placeholder=""
            suggestType="available_fillings"
            onSelect={handleFillingSuggest}
            onChange={(event) => handleFillingPatternName(event.target.value)}
            readOnly={fillingPattern ? true : false}
          />
          {fillingPattern ? (
            <div onClick={clearFillingSuggest}>
              <img
                className={styles.bread_add_recipe_call_pattern_select_clear}
                src={clearCrossIcon}
                alt="x"
              />
            </div>
          ) : (
            ''
          )}
          <SubmitButton
            label="追加する"
            color={fillingPattern ? 'orange' : 'gray'}
            size="small"
            icon="none"
            onClick={handleAddFillingPattern}
          />
        </div>
        <SubmitButton
          label="フィリングを作成する"
          color="orange"
          size="xmiddle"
          icon="plus"
          onClick={() => handleSubmit('/filling/add?recipeBreadId=' + breadId)}
        />
      </div>
      <div className={styles.bread_add_recipe_product_information}>
        <AddFormProductInformation
          formInfos={fillingProductInformations}
          unit={breadDisplayPercent ? '%' : 'g'}
          errorText={fillingProductInformationsErrorText}
          handleProductInformation={handleFillingProductInformation}
        />
      </div>
      <div className={styles.bread_add_recipe_sum}>
        <InputTextAndUnitWithLightLabel
          labelName="合計"
          description=""
          line={1}
          type="text"
          size="minimum"
          unit={breadDisplayPercent ? '%' : 'g'}
          readOnly={true}
          value={fillingProductInformations.reduce(
            (sum, fillingProductInformation) =>
              String(
                new Big(Number(fillingProductInformation.middleKneadPercent))
                  .plus(Number(fillingProductInformation.authenticKneadPercent))
                  .plus(Number(sum))
                  .toNumber(),
              ),
            '0',
          )}
          placeholder=""
        />
        <div className={styles.bread_add_recipe_sum_space}></div>
      </div>
      <div className={styles.bread_add_recipe_sum_margin}>
        <InputTextAndUnitWithLightLabel
          labelName="商品1個あたりの重量（焼成前生地量）"
          required={true}
          inputBoxRight={true}
          description=""
          line={2}
          type="text"
          size="small"
          value={amountDoughBeforeBaking}
          placeholder=""
          unit="g"
          errorText={amountDoughBeforeBakingErrorText}
          onChange={(event) =>
            handleAmountDoughBeforeBaking(event.target.value)
          }
        />
        <div className={styles.bread_add_recipe_sum_space}></div>
      </div>
      <div className={styles.bread_add_recipe_sum_margin}>
        {breadDisplayGram ? (
          <SubmitButton
            label="1個あたりを自動計算する"
            color="orange"
            size="xmiddle"
            icon="none"
            onClick={handleCalcAmountDoughbeforeBaking}
          />
        ) : (
          <SubmitButton
            label="1個あたりを自動計算する"
            color="gray"
            size="xmiddle"
            icon="none"
            onClick={() => {}}
          />
        )}
        <div className={styles.bread_add_recipe_sum_space}></div>
      </div>
      <div className={styles.bread_add_recipe}>
        <div className={styles.bread_add_recipe_section}></div>
      </div>
      <div className={styles.bread_add_recipe_sum_margin}>
        <InputTextAndUnitWithLightLabel
          labelName="ロス率（全工程での生地収量・発酵・分割によるロス）"
          inputBoxRight={true}
          description=""
          line={2}
          type="text"
          size="small"
          value={lossRate}
          placeholder=""
          unit="%"
          errorText={lossRateErrorText}
          onChange={(event) => handleLossRate(event.target.value)}
        />
        <div className={styles.bread_add_recipe_sum_space}></div>
      </div>
      <div className={styles.bread_add_recipe_sum_margin}>
        <div className={styles.bread_add_recipe_sum_display_unit}>
          <RadioButton
            isChecked={afterBakingPercent}
            label="%"
            onChange={handleAfterBakingUnit}
          />
          <RadioButton
            isChecked={afterBakingGram}
            label="g"
            onChange={handleAfterBakingUnit}
          />
        </div>
        {afterBakingPercent ? (
          <InputTextAndUnitWithLightLabel
            labelName="焼減率"
            inputBoxRight={true}
            description=""
            line={2}
            type="text"
            size="small"
            value={burnoutRate}
            placeholder=""
            unit="%"
            errorText={burnoutRateErrorText}
            onChange={(event) => handleBurnoutRate(event.target.value)}
          />
        ) : (
          <InputTextAndUnitWithLightLabel
            labelName="商品１個あたりの焼成後の重量"
            inputBoxRight={true}
            description=""
            line={2}
            type="text"
            size="small"
            value={amountDoughAfterBaking}
            placeholder=""
            unit="g"
            errorText={amountDoughAftereBakingErrorText}
            onChange={(event) =>
              handleAmountDoughAfterBaking(event.target.value)
            }
          />
        )}
        <div className={styles.bread_add_recipe_sum_space}></div>
      </div>
      <div className={styles.bread_add_recipe}>
        <div className={styles.bread_add_recipe_section}>メモ欄</div>
      </div>
      <TextAreaWithLightLabel
        labelName=""
        description=""
        line={1}
        maxLength={1000}
        rows={8}
        size="full"
        value={memo}
        placeholder=""
        onChange={(event) => handleMemo(event.target.value)}
      />
      <div className={styles.bread_add_recipe}>
        <div className={styles.bread_add_recipe_section}>
          工程(フリーテキスト)
        </div>
      </div>
      <TextAreaWithLightLabel
        labelName=""
        description=""
        line={1}
        maxLength={1000}
        rows={16}
        size="full"
        value={process}
        placeholder=""
        onChange={(event) => handleProcess(event.target.value)}
      />
      <div className={styles.breadAddButtonContainer}>
        <SubmitButton
          label="キャンセル"
          color="orange_outlined"
          size="large"
          icon="none"
          onClick={() => navigate('/initload')}
        />
        <span className={styles.breadAddButtonSpacer}></span>
        {/* {recipeStatus === RecipeStatus.DRAFT ? (
          <>
            <SubmitButton
              label="保存し中断する"
              color="orange_outlined"
              size="large"
              icon="none"
              onClick={() => handleSubmit(true)}
            />
            <span className={styles.breadAddButtonSpacer}></span>
          </>
        ) : (
          ''
        )} */}
        <SubmitButton
          label={
            recipeStatus === BreadStatus.PUBLIC
              ? '下書きに戻して保存する'
              : '下書き保存する'
          }
          color="orange_outlined"
          size="large"
          icon="none"
          onClick={() => handleSubmit('detail', true)}
        />
        <span className={styles.breadAddButtonSpacer}></span>
        <SubmitButton
          label="登録(更新)する"
          color="orange"
          size="large"
          icon="none"
          onClick={() => handleSubmit('detail')}
        />
      </div>
    </div>
  )
}

export default BreadAddTemplate
